@import "./variables";
@import "./breakpoints";

.scrollLock {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.how-it-works {
  [role="tabpanel"] {
    width: 100%;
  }
  .swiper-slide {
    top: 0px;
  }
  // .box-flex-container{
  //   display: flex;
  //   flex-wrap: wrap;
  // }
  // .box-flex-container::after{
  //   content: "";
  //   flex: auto;
  // }
  // .box-flex{
  //   flex: 0 0 calc(33.33% - 25px);
  //   margin-bottom: 20px;
  //   @include xl {
  //      flex: 0 0 calc(50% - 25px);
  //   }
  //   @include sm {
  //     flex: 0 0 100%;

  //   }
  // }
  .left-section-head-hw {
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    font-size: 54px;
    line-height: 120%;
    color: #000000;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    justify-content: flex-start;
    @include xl {
      font-size: 44px;
    }
    @include md {
      margin-top: 14px;
    }
    @include sm {
      font-size: 36px;
    }
  }
  .left-section-head-hw-arabic {
    font-family: $Font_NotoNaskhArabicRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 130%;
    text-align: right;
    color: #000000;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    @include xl {
      font-size: 44px;
    }
    @include md {
      margin-top: 14px;
    }
    @include sm {
      font-size: 38px;
    }
  }
  .left-section-head-amber-app {
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 120%;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include md {
      font-size: 28px;
    }
  }
  .left-section-head-amber-app-arabic {
    font-family: $Font_NotoNaskhArabicRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 130%;
    text-align: right;
    color: #000000;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    @include md {
      font-size: 28px;
    }
  }
  .bg-green-head {
    background-color: RGBA(231, 240, 223, 1);
  }
  .bg-green {
    background-color: RGBA(231, 240, 223, 0.7);
  }
  .bg-green-opacity {
    background-color: RGBA(231, 240, 223, 0.5);
  }
  // .test{
  //   background-color: rgba(255, 0, 0, 0.5);
  // background-image: url('https://e0.pxfuel.com/wallpapers/188/66/desktop-wallpaper-white-background-white.jpg');
  // background-size: cover;
  // background-blend-mode: multiply;
  // }
  .bg-dark-green-head {
    background-color: #274a2e;
    color: #ffffff !important;
  }
  .bg-dark-green {
    background-color: RGBA(39, 74, 46, 0.25);
  }
  .bg-dark-green-opacity {
    background-color: RGBA(39, 74, 46, 0.14);
  }
  .bg-dark-orange-head {
    background-color: RGBA(160, 50, 20, 1);
    color: #ffffff !important;
  }
  .bg-dark-orange {
    background-color: RGBA(160, 50, 20, 0.25);
  }
  .bg-dark-orange-opacity {
    background-color: RGBA(160, 50, 20, 0.14);
  }
  .bg-dark-purple-head {
    background-color: RGBA(20, 9, 83, 1);
    color: #ffffff !important;
  }
  .bg-dark-purple {
    background-color: RGBA(20, 9, 83, 0.25);
  }
  .bg-dark-purple-opacity {
    background-color: RGBA(20, 9, 83, 0.14);
  }
  .bg-dark-brown-head {
    background-color: RGBA(78, 11, 57, 1);
    color: #ffffff !important;
  }
  .bg-dark-brown {
    background-color: RGBA(78, 11, 57, 0.25);
  }
  .bg-dark-brown-opacity {
    background-color: RGBA(78, 11, 57, 0.14);
  }

  .table-row-name {
    font-family: $Font_Montserrat;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 130% !important;
    color: #000000 !important;
  }
  .table-head-text {
    font-family: $Font_ArchivoBlack;
    line-height: 12px !important;
    text-align: center !important;
    letter-spacing: 0.2em !important;
    text-transform: uppercase !important;
    font-size: 10px !important;
    min-width: 160px;
    @include _3xl {
      min-width: 120px;
    }
    @include xxl {
      min-width: 90px;
    }
    @include xl {
      min-width: 50px;
    }
    @include lg {
      min-width: 40px;
    }
  }
  .table-head-text-arabic {
    font-family: $Font_NotoNaskhArabicRegular;
    line-height: 12px !important;
    text-align: center !important;
    letter-spacing: 0em !important;
    text-transform: uppercase !important;
    font-size: 15px !important;
    min-width: 160px;
    @include _3xl {
      min-width: 120px;
    }
    @include xxl {
      min-width: 90px;
    }
    @include xl {
      min-width: 50px;
    }
    @include lg {
      min-width: 40px;
    }
  }
  .table-head-subtext {
    font-family: $Font_Montserrat !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 130% !important;
    text-align: center !important;
    color: rgba(0, 0, 0, 0.7) !important;
    margin-top: 4px;
  }
  .table-head-subtext-arabic {
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    direction: rtl;

    margin-top: 4px;
  }
  .table-head-subtext-light {
    font-family: $Font_Montserrat !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 130% !important;
    text-align: center !important;
    color: rgba(255, 255, 255, 0.7) !important;
    margin-top: 4px;
    max-width: 143px;
    width: 100%;
  }
  .table-head-subtext-light-arabic {
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 4px;
  }
  .hw-tab-head {
    width: 100%;
    font-family: $Font_ArchivoBlack;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-align: left;
  }

  .hw-tab-head-arabic {
    width: 100%;
    text-transform: uppercase;
    font-family: $Font_TajawalBold;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 110%;
    text-align: right;
    padding-right: 20px;
    direction: rtl;

    @include xs {
      min-width: 200px;
    }
  }
  @include md {
    min-width: 100%;
  }
  .img-tick {
    background-color: #000000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 8px;
    height: 8px;
  }
  .hw-tab-text {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    margin-top: 4px;
    text-align: left;
    width: 100%;
    opacity: 0.7;
  }
  .hw-tab-text-arabic {
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    margin-top: 4px;
    text-align: right;
    width: 100%;
    padding-right: 20px;
    opacity: 0.7;
    direction: rtl;
  }
  .container {
    position: relative;
    display: flex;
    width: auto;
    justify-content: flex-end;
    .tooltip {
      display: none;
      position: absolute;
      z-index: 1;
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #333;
      color: #fff;
      padding: 5px;
      border-radius: 4px;
      font-size: 14px;
      width: 100px;
      word-wrap: break-word;
    }

    &:hover .tooltip {
      display: block;
    }
  }
  .question-mark {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #90969e;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .table-first-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .left-section-head {
    height: 130px;
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    font-size: 54px;
    line-height: 120%;
    color: #000000;
    display: flex;
    align-items: center;
    @include lg {
      font-size: 44px;
    }
    @include sm {
      font-size: 36px;
    }
  }
  .left-section-head-arabic {
    font-family: $Font_NotoNaskhArabicRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 130%;
    color: #000000;
    display: flex;
    align-items: center;
    direction: rtl;

    @include lg {
      font-size: 44px;
    }
    @include sm {
      font-size: 38px;
      line-height: 120%;
    }
  }
}
.as-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f9f9fd;
  height: 565px;
  padding: 80px 160px 80px 160px;
  @include xl {
    padding: 10px 20px 10px 20px;
  }
  .bottom-box {
    width: 100%;
    max-width: 480px;
  }
  .button-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  }
  .button-submit {
    font-family: $Font_Montserrat;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    font-size: 14px;
    min-width: 160px;
    height: 42px;
    background: #000000;
    border-radius: 34px;
    color: #ffffff;
    cursor: pointer;
  }
  .button-submit-arabic {
    font-family: $Font_TajawalMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 130%;
    text-align: center;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    min-width: 160px;
    height: 42px;
    background: #000000;
    border-radius: 34px;
    cursor: pointer;
    direction: rtl;
  }
  .total-text {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #000000;
    width: 100%;
    max-width: 576px;
    text-align: center;
    justify-content: center;
    margin-top: 14px;
    flex-direction: row;
  }
  .total-text-arabic {
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #000000;
    width: 100%;
    max-width: 576px;
    text-align: center;
    justify-content: center;
    margin-top: 14px;
    direction: rtl;

    flex-direction: row-reverse;
  }
  .total-text-bold {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-left: 8px;
    margin-right: 8px;
  }
  .as-head {
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 120%;
    text-align: center;
    color: #000000;
    margin-bottom: 16px;
    @include md {
      font-size: 28px;
    }
  }
  .as-head-arabic {
    font-family: $Font_NotoNaskhArabicRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 120%;
    text-align: center;
    color: #000000;
    direction: rtl;

    margin-bottom: 16px;
    @include md {
      font-size: 30px;
    }
  }
  .as-text {
    width: 800px;
    height: 56px;
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #5a5f64;
    margin-bottom: 32px;
    text-align: center;

    @include xl {
      width: 100%;
      max-width: 576px;
    }
  }
  .as-text-arabic {
    width: 800px;
    height: 56px;
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 130%;
    color: #5a5f64;
    margin-bottom: 32px;
    direction: rtl;
    text-align: center;
    @include xl {
      width: 100%;
      max-width: 576px;
    }
  }
  .as-subtext {
    max-width: 480px;
    width: 100%;
    height: 100%;
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #5a5f64;
    margin-bottom: 32px;
    text-align: left;
  }
  .as-subtext-arabic {
    max-width: 480px;
    width: 100%;
    height: 100%;
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 130%;
    color: #5a5f64;
    margin-bottom: 32px;
    text-align: right;
    direction: rtl;
  }
  .as-custom-main {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 576px;
    margin-bottom: 16px;
  }
  .as-custom-radio {
    font-family: $Font_Montserrat_Medium;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #dfe1ea;
    border-radius: 40px;
    height: 40px;
    font-size: 14px;
    padding-left: 16px;
    padding-right: 16px;

    &[data-lang="arabic"] {
      font-family: $Font_TajawalMedium;
      font-size: 17px;
    }
  }
  .status-radio-group {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    gap: 16px;

    &[data-lang="arabic"] {
      direction: rtl;
    }
  }
  .status-radio-button {
    padding-left: 0;
    &[data-lang="arabic"] {
      padding-left: 16px;
      padding-right: 0px;
    }
  }
  // Codepen spesific styling - only to center the elements in the pen preview and viewport
  .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
}
.select-how {
  .select-label {
    font-family: $Font_Montserrat_Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 8px;
    text-align: left;
  }
  .select-label-arabic {
    font-family: $Font_TajawalMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 8px;
    text-align: right;
  }
  .AED {
    background-color: #f3f3fa;
    min-width: 99px;
    max-width: 99px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    border-right: 1px solid #ced0d7;
    flex-direction: row;
    font-family: $Font_Montserrat_Medium;
    padding-left: 0%;
    height: 41px;
    border-radius: 4px;
  }
  .AED-arabic {
    background-color: #f3f3fa;
    min-width: 99px;
    max-width: 99px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #ced0d7;
    flex-direction: row;
    font-family: $Font_TajawalMedium;
    margin-left: 5px;
    margin-right: 0px;
    padding-right: 0%;
    height: 41px;
    border-radius: 4px;
  }
}
.your-questions-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0px 80px 0px;
  width: 100%;
  height: 100%;
  background-color: #274a2e;
  color: #ffffff;
  @include md {
    padding: 32px 0px 32px 0px;
  }
  .your-questions-main-head {
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 120%;
    color: #ffffff;
    margin-bottom: 32px;
    @include md {
      margin-bottom: 16px;
      font-size: 28px;
    }
  }
  .your-questions-main-head-arabic {
    font-family: $Font_NotoNaskhArabicRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 120%;
    text-align: right;
    color: #ffffff;
    margin-bottom: 32px;
    @include md {
      margin-bottom: 16px;
      font-size: 30px;
    }
  }
  .credit {
    background-color: transparent;
    color: white;
    border: 0;
    border-bottom: 1px solid #bdbdbd6b;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    height: 72px;
    width: 100%;
    justify-content: space-between;
    display: flex;
  }
  .credit-arabic {
    font-family: $Font_TajawalMedium;
    display: flex;
    align-items: center;
    text-align: right;
    color: #ffffff;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #bdbdbd6b;
    font-weight: 500;
    font-size: 19px;
    line-height: 130%;
    height: 72px;
    width: 100%;
    justify-content: space-between;
  }
  option {
    padding: 0 30px 0 10px;
    min-height: 40px;
    display: flex;
    align-items: center;
    background: #333;
    border-top: #222 solid 1px;
    position: absolute;
    top: 0;
    width: 100%;
    pointer-events: none;
    order: 2;
    z-index: 1;
    transition: background 0.4s ease-in-out;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
  }

  .your-questions-box {
    width: 100%;
    max-width: 640px;
    height: 100%;
    max-height: 219px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    @include md {
      width: 90%;
      max-height: 246px;
    }
  }
}
.AmberInf {
  .section {
    width: 100%;
  }
  .section-head {
    font-size: 54px;
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: #000000;
    margin-bottom: 56px;
    margin-top: 40px;
    @include md {
      font-size: 44px;
      margin-bottom: 24px;
    }
  }
  .section-head-arabic {
    font-family: $Font_NotoNaskhArabicRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 130%;
    text-align: right;
    color: #000000;
    margin-bottom: 56px;
    margin-top: 40px;
    @include md {
      font-size: 50px;
      margin-bottom: 24px;
    }
  }
  .section-head-amber {
    font-size: 54px;
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: #000000;
    margin-bottom: 32px;
    margin-top: 16px;
    @include md {
      font-size: 44px;
      margin-bottom: 24px;
    }
  }
  .section-head-amber-arabic {
    font-family: $Font_NotoNaskhArabicRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 130%;
    text-align: right;
    color: #000000;
    margin-bottom: 32px;
    margin-top: 16px;
    @include md {
      font-size: 50px;
      margin-bottom: 24px;
    }
  }
  .section-text-head {
    height: 18px;
    font-family: $Font_ArchivoBlack;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 8px;
  }
  .section-text-head-arabic {
    font-family: $Font_TajawalBold;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 110%;
    text-align: right;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 8px;
  }
  .section-text-amber-head {
    font-family: $Font_ArchivoBlack;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2em;
    max-width: 350px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 8px;
    height: "auto";
    margin-top: "0px";
    @include sm {
      margin-bottom: 16px;
    }
  }
  .section-text-head-amber-arabic {
    font-family: $Font_TajawalBold;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 110%;
    text-align: right;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 8px;
    @include sm {
      margin-bottom: 16px;
    }
  }
  .section-text {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #5a5f64;
    margin-bottom: 56px;
    @include md {
      margin-bottom: 24px;
    }
  }
  .section-text-arabic {
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: right;
    color: #5a5f64;
    margin-bottom: 56px;
    @include md {
      margin-bottom: 24px;
    }
  }
  .section-text-amber {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #5a5f64;
    margin-bottom: 32px;
    @include md {
      margin-bottom: 24px;
    }
  }
  .section-text-arabic-amber {
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    text-align: right;
    color: #5a5f64;
    margin-bottom: 32px;
    @include md {
      margin-bottom: 24px;
    }
  }
  .section-text2 {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #5a5f64;
    margin-bottom: 0px;
    @include xl {
      margin-bottom: 0px;
    }
  }
  .section-text2-arabic {
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: right;
    color: #5a5f64;
    margin-bottom: 0px;
    @include xl {
      margin-bottom: 0px;
    }
  }
  .button-submit {
    font-family: $Font_Montserrat;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    font-size: 14px;
    min-width: 160px;
    height: 42px;
    background: #000000;
    border-radius: 34px;
    margin-bottom: 30px;
    color: #ffffff;
    cursor: pointer;
  }
}
.keep {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  height: 409px;
  background: #e5dbf5;
  justify-content: center;
  padding-left: 11%;
  padding-right: 11%;
  @include md {
    height: 296px;
  }
  .keep-up {
    width: 100%;
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 120%;
    color: #000000;
    margin-bottom: 16px;
    @include md {
      font-size: 28px;
    }
  }
  .keep-up-arabic {
    font-family: $Font_NotoNaskhArabicRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    text-align: right;
    width: 100%;
    line-height: 120%;
    color: #000000;
    @include md {
      font-size: 30px;
    }
  }
  .section-text {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 56px;
  }
  .section-text-arabic {
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 130%;
    text-align: right;
    color: #000000;
    margin-bottom: 56px;
  }
  .button-submit {
    font-family: $Font_Montserrat;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    font-size: 14px;
    min-width: 160px;
    height: 42px;
    background: #000000;
    border-radius: 34px;
    color: #ffffff;
    cursor: pointer;
  }
  .button-submit-arabic {
    font-family: $Font_TajawalMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 130%;
    text-align: center;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    min-width: 160px;
    height: 42px;
    background: #000000;
    border-radius: 34px;
    cursor: pointer;
  }
}
.select-right-icon {
  direction: rtl;
}

.select-label-arabic-padding-arabic {
  > .MuiSelect-select {
    padding-right: 0px !important;
  }
}
