@import "./variables";
@import "./breakpoints";
.reward-system {
  .hello-message {
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 120%;
    color: #000000;
    width: 100%;
    @include md {
      font-size: 28px;
    }

    &[data-lang="arabic"] {
      font-family: $Font_NotoNaskhArabicRegular;
      font-weight: 400;
      font-size: 30px;
      line-height: 36px;
    }
  }
  .user-name {
    @extend .hello-message;
    font-size: 54px;
    position: relative;
    @include md {
      font-size: 44px;
      position: relative;
    }
    @include sm {
      font-size: 36px;
      position: relative;
    }
  }
  .user-name-arabic {
    font-family: $Font_NotoNaskhArabicRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 130%;
    text-align: right !important;
    color: #000000;
    width: 100%;
    padding-bottom: 64px;
    @include md {
      font-size: 36px;
      padding-bottom: 64px;
    }
    @include sm {
      font-size: 36px;
      padding-bottom: 32px;
    }
  }
  .promotion-header-user-name {
    @include sm {
      font-size: 44px;
      line-height: 120%;
      &[data-lang="arabic"] {
        font-family: $Font_NotoNaskhArabicRegular;
        font-weight: 400;
        font-size: 50px;
        line-height: 60px;
      }
    }
  }
  .Box-head {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #5a5f64;
    height: 18px;
    &[data-lang="arabic"] {
      font-family: $Font_TajawalRegular;
      font-size: 17px;
      height: 22.1px;
      color: #5a5f64;
    }
  }
  .Box-text {
    font-family: $Font_Montserrat_Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    @include sm {
      font-size: 14px;
    }
    &[data-lang="arabic"] {
      font-family: $Font_TajawalMedium;
      font-size: 17px;
      line-height: 22.1px;
    }
  }
  .Box-text-spend {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;

    @include between("sm", "xl") {
      margin-bottom: 24px;
    }

    &[data-lang="arabic"] {
      font-family: $Font_TajawalRegular;
      font-weight: 400;
      font-size: 17px;
      line-height: 22.1px;
      @include sm {
        font-size: 19px;
        line-height: 24.7px;
      }
    }
  }
  .info-labels-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    @include sm {
      flex-direction: column;
      justify-content: initial;
      align-items: initial;
      gap: 16px;
    }
  }
  .brandmark-text {
    font-family: $Font_ArchivoBlack;
    font-size: 10px;
    line-height: 11px;
    letter-spacing: 0.3em;

    &[data-lang="arabic"] {
      font-family: $Font_NotoNaskhArabicRegular;
      font-size: 15px;
      line-height: 11px;
      letter-spacing: 0em;
    }
    @include sm {
      font-size: 6px;
    }
  }

  .brandmark-image {
    @include sm {
      height: 21px;
    }
  }
}

.responsive-table {
  .responsive-table-th {
    border-bottom: 1px solid #000000;
  }
  .responsive-table-head {
    font-family: $Font_Montserrat_Medium !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 130% !important;
    color: #000000 !important;
    padding: 16px 0px 16px 0px;
  }
  .responsive-table-head-arabic {
    font-family: $Font_TajawalMedium !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 17px !important;
    line-height: 130% !important;
    color: #000000 !important;
    padding: 16px 0px 16px 0px;
  }
  .responsive-table-body {
    font-family: $Font_Montserrat !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 130% !important;
    color: #000000;
    padding: 16px 0px 16px 0px;
  }
  .responsive-table-body-arabic {
    font-family: $Font_TajawalRegular;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: right;
  }
  .responsive-table-body-change-color {
    color: #f66464;
  }
  .responsive-table-points {
    gap: 4px;
    width: 64px;
    height: 20px;
    background: rgb(243, 243, 250);
    border-radius: 50px;
    font-family: $Font_Montserrat_Medium;
    font-size: 10px;
    line-height: 14px;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(0, 0, 0);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .responsive-table-points-arabic {
    @extend .responsive-table-points;
    font-family: $Font_TajawalRegular;
    font-size: 11px;
  }

  .responsive-table-row {
    height: 50px;
  }
  .box-text {
    padding: 20px;
    height: 18px;
    font-family: $Font_Montserrat;
    font-size: 14px;
    line-height: 130%;
    width: 50%;
    font-weight: 400;
  }
  .box-text-label {
    @extend .box-text;
    font-family: $Font_Montserrat_Medium;
  }
  .box-text-arabic {
    padding: 20px;
    height: 18px;
    font-family: $Font_TajawalRegular;
    font-size: 17px;
    line-height: 130%;
    width: 50%;
    font-weight: 400;
  }
  .box-text-label-arabic {
    @extend .box-text-arabic;
    font-family: $Font_TajawalMedium;
  }
  .box-main {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
.reward-card {
  border-radius: 16px;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  &[data-lang="arabic"] {
    direction: rtl;
  }

  .top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .brand-top-left {
    font-family: $Font_ArchivoBlack;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 13px */
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .brand-top-left-arabic {
    font-family: $Font_TajawalRegular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 13px */
    letter-spacing: 0px;
    text-transform: uppercase;
  }
  .badge-container {
    width: 44px;
    display: flex;
    padding: 4px 8px;
    border-radius: 50px;
    background-color: white;
    justify-content: center;
    align-items: center;
  }
  .badge-text {
    font-family: $Font_Montserrat;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: black;
  }
  .info-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    .brand-image-container {
      width: 74px;
      height: 32px;
    }
    .descriptiption {
      font-family: $Font_Montserrat_Medium;
      font-size: 14px;
      font-weight: 500;
      line-height: 18.2px;
      text-align: center;
    }
  }
  .expire-date-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .expires-text {
      font-family: $Font_Montserrat;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
    }
    .date {
      font-family: $Font_Montserrat;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
    }
  }
  &[data-lang="arabic"] {
    .badge-text {
      font-family: $Font_TajawalMedium;
    }
    .info-container {
      .descriptiption {
        font-family: $Font_TajawalMedium;
      }
    }
    .expire-date-container {
      .expires-text {
        font-family: $Font_TajawalRegular;
      }
      .date {
        font-family: $Font_TajawalRegular;
      }
    }
  }

  .brandmark-icon {
    left: calc(100% - 250px);
    top: calc(30%);
    max-width: 74px;
    height: auto;
  }
}

.rewards {
  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper .swiper-slide {
    top: 0px;
    margin-top: 18px;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &[data-lang="arabic"] {
    .swiper-button-prev {
      right: initial;
      left: 40px;
    }
    .swiper-button-next {
      right: initial;
      left: 0px;
    }
    direction: rtl;
  }

  .your-rewards-header {
    font-family: $Font_ArchivoBlack;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
    font-weight: bold;
    margin-bottom: 8px;
    letter-spacing: 2.8px;
    position: absolute;
    left: 0;

    &[data-lang="arabic"] {
      font-family: $Font_TajawalRegular;
      font-size: 18px;
      line-height: 110%;
      letter-spacing: 0.36px;
      right: 0;
      left: initial;
    }
  }
}
