@import "./variables";
@import "./breakpoints";

.privacyAndPolicy {
  &[data-lang="en"] {
    text-align: left;
    strong {
      font-family: $Font_PlayfairDisplay;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      color: #000000;

      @include xs {
        font-size: 28px;
      }

      @include md {
        font-size: 36px;
      }
    }
    p {
      font-family: $Font_Montserrat;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      color: #000000;

      @include xs {
        font-size: 14px;
      }

      @include md {
        font-size: 16px;
      }
    }
  }
  &[data-lang="arabic"] {
    text-align: right;
    strong {
      font-family: $Font_NotoNaskhArabicRegular;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;

      @include xs {
        font-size: 20px;
      }

      @include md {
        font-size: 28px;
      }
    }
    li {
      text-align: right;
    }
    ol {
      text-align: right;
    }
    ul {
      text-align: right;
    }
    p {
      font-family: $Font_TajawalRegular;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      color: #000000;
    }

    @include xs {
      font-size: 14px;
    }

    @include md {
      font-size: 16px;
    }
  }
}
