@import "./variables";
@import "./breakpoints";

.purchase {
  text-align: center;
  padding: 20px;
  .no-purchase-title {
    font-family: $Font_Montserrat_Medium;
    font-size: 20px;
    font-weight: 600;
    line-height: 130%;
    text-align: center;

    &[data-lang="arabic"] {
      font-family: $Font_TajawalMedium;
      font-size: 24px;
      line-height: 130%;
    }
  }
  .no-purchase-text {
    font-family: $Font_Montserrat !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    color: #5a5f64;
    text-align: center;

    &[data-lang="arabic"] {
      font-family: $Font_TajawalMedium !important;
      font-size: 17px;
      line-height: 130%;
    }
  }
  .actions-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

.browse-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  border: 1px solid #5a5f64;
  border-radius: 34px;
  color: black;
  font-family: $Font_Montserrat_Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 130%;
  background-color: white;
  cursor: pointer;
  &:hover {
    border-color: black;
  }
  &:active {
    opacity: 0.5;
  }
}

.browse-button-arabic {
  @extend .browse-button;
  font-family: $Font_TajawalRegular;
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
}

.sort-filter {
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 100px;
  border: 1px solid var(--greys-grey-03, #ced0d7);
  background: var(--primary-white, #fff);
  font-family: $Font_Montserrat_Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  position: relative;
  background-size: 20px;
  -webkit-appearance: none;
  min-width: 0 !important;
}

.sort-filters-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 8px;
  gap: 8px;
}

.stor-filters-main {
  width: 100%;
  label {
    color: var(--primary-black, #000);
    font-family: $Font_Montserrat_Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }
}
