@import "./variables";
@import "./breakpoints";

.faq {
  &[data-lang="en"] {
    text-align: left;
    strong {
      font-family: $Font_PlayfairDisplay;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      color: #000000;

      @include xs {
        font-size: 28px;
      }

      @include md {
        font-size: 36px;
      }
    }
    p {
      font-family: $Font_Montserrat;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      color: #000000;

      @include xs {
        font-size: 14px;
      }

      @include md {
        font-size: 16px;
      }
    }
  }
  &[data-lang="arabic"] {
    text-align: right;
    strong {
      font-family: $Font_NotoNaskhArabicRegular;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;

      @include xs {
        font-size: 20px;
      }

      @include md {
        font-size: 28px;
      }
    }
    li {
      text-align: right;
    }
    ol {
      text-align: right;
    }
    ul {
      text-align: right;
    }
    p {
      font-family: $Font_TajawalRegular;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      color: #000000;
    }

    @include xs {
      font-size: 14px;
    }

    @include md {
      font-size: 16px;
    }
  }

  &.faq-section {
    .css-tagtcf {
      max-width: 800px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .MuiPaper-root + .MuiPaper-root {
      margin-top: 54px;
    }

    .MuiAccordionSummary-expandIconWrapper {
      display: none;
    }

    .MuiList-root.MuiList-padding {
      padding-inline-start: 0;

      .MuiListItem-root.MuiListItem-gutters {
        list-style: none;
      }
    }
  }
}
.contact-us {
  display: flex;
  flex-direction: column;
  gap: 40px;
  &[data-lang="arabic"] {
    direction: rtl;
  }
  .contact-us-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 40px;
    @include xl {
      flex-direction: column;
    }
  }
  .box-1 {
    width: 62.25%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    @include xl {
      width: 100%;
    }
    .head {
      color: var(--primary-black, #000);
      font-family: $Font_PlayfairDisplay;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      &[data-lang="arabic"] {
        font-family: $Font_NotoNaskhArabicRegular;
      }
    }
  }
  .box-2 {
    width: 37.75%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @include xl {
      width: 100%;
    }
    .head {
      color: var(--primary-black, #000);
      font-family: $Font_Montserrat_Medium;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      &[data-lang="arabic"] {
        font-family: $Font_TajawalMedium;
      }
    }

    .text-space-container {
      display: flex;
      gap: 16px;
      flex-direction: column;
    }
    .text-space {
      display: flex;
      justify-content: space-between;
    }
  }
  .box-3 {
    width: 33%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    border: 1px solid var(--greys-grey-03, #ced0d7);

    @include sm {
      width: -webkit-fill-available;
    }
    .box-3-child {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .head {
      color: var(--primary-black, #000);
      font-family: $Font_Montserrat_Medium;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      &[data-lang="arabic"] {
        font-family: $Font_TajawalMedium;
      }
    }
    .online {
      border-radius: 50%;
      background-color: #4dc862;
      width: 14px;
      height: 14px;
    }
    .offline {
      border-radius: 50%;
      background-color: rgb(200, 77, 77);
      width: 14px;
      height: 14px;
    }
  }
  .mobile-convert {
    display: flex;
    flex-direction: row !important;
    justify-content: center !important;
    gap: 40px;

    @include sm {
      flex-direction: column !important;
      gap: 16px;
    }
  }
  .start-position {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  .text {
    color: var(--primary-black, #000);
    font-family: $Font_Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    &[data-lang="arabic"] {
      font-family: $Font_TajawalRegular;
    }
  }
  .text-hour {
    color: var(--primary-black, #000);
    font-family: $Font_Montserrat_Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    &[data-lang="arabic"] {
      font-family: $Font_TajawalMedium;
    }
  }
}
