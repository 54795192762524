@import "./variables";
@import "./breakpoints";
.WPlogin {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #505050;
  height: 100vh;
  @include md {
    background-color: #ffffff;
  }
  @include smheight2 {
    background-color: #ffffff;
    height: calc(100% - 40px);
    padding: 20px;
  }
  @include smheight2 {
    @include sm {
      background-color: #ffffff;
      height: calc(100% - 40px);
      padding: 20px;
    }
  }
  .button-submit-loading {
    font-family: $Font_Montserrat;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    font-size: 14px;
    height: 42px;
    width: 288px;
    margin-top: 47px;
    background: rgba(90, 95, 100, 0.5);
    border-radius: 34px;
    color: #ffffff;
    cursor: pointer;
    @include sm {
      width: 100%;
      max-width: 100%;
    }
  }

  .login-head-WA {
    width: 553px;
    height: auto;
    left: 444px;
    top: 192px;
    text-align: center;
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 120%;
    @include md {
      width: 350px;
      height: 68px;
      font-size: 28px;
    }
    @include sm {
      width: 350px;
      height: 68px;
      font-size: 20px;
    }
    @include xs {
      width: 250px;
      height: 48px;
      font-size: 20px;
    }
  }
  .login-head-WA-arabic {
    width: 553px;
    height: auto;
    left: 444px;
    top: 192px;
    text-align: center;
    font-family: $Font_NotoNaskhArabicRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 120%;
    @include md {
      width: 350px;
      height: 68px;
      font-size: 28px;
    }
    @include sm {
      width: 350px;
      height: 68px;
      font-size: 20px;
    }
    @include xs {
      width: 250px;
      height: 48px;
      font-size: 20px;
    }
  }

  .remember-signin-arabic {
    align-items: center;
    align-content: center;
    margin-top: 20px !important;
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    height: 20px;
  }

  label {
    top: -5px !important;
  }
  .MuiOutlinedInput-root {
    padding-right: 5px !important;
  }
  .MuiInputLabel-root {
    font-size: 0.86rem !important;
  }
  .MuiFormHelperText-root {
    color: #f66464 !important;
    margin-left: 0px !important;
    font-size: 14px !important;
    line-height: 130% !important;
    margin-top: 8px !important;
  }
  .WPrightpage {
    display: flex;
    align-items: center;
    justify-content: center;
    // background-image: $backgroundRightImage;
    height: 100%;
    width: $WPrightpageWidth;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    @include xl {
      display: none;
    }
  }
  .remember-signin {
    align-items: center;
    align-content: center;
    margin-top: 20px !important;
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    height: 20px;
    color: #000000;
  }
  .WPrighttext {
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    line-height: 120%;
    color: #ffffff;
    width: 100%;
    max-width: 540px;
  }
  .WPleftpage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    height: 100%;
    width: $WPleftpageWidth;
    position: relative;

    @include xl {
      width: 100%;
    }

    @include smheight2 {
      background-color: #ffffff;
    }
    &[data-path="loginPhone"] {
      justify-content: center;
      @include md {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
  .left-page-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    top: 14.7%;
    position: relative;
    @include sm {
      top: 50px;
    }
  }
  .left-page-form {
    max-width: 460px;
    width: 100%;
    position: relative;
    top: 215px;
    height: calc(100% - 215px);
    @include sm {
      top: 123px;
      height: calc(100% - 123px);
      max-width: calc(100% - 40px);
    }
    @include xs {
      max-width: 270px;
    }
    .login-head {
      font-style: normal;
      font-weight: 400;
      font-size: 44px;
      line-height: 120%;
      color: #000000;
      margin-bottom: 24px;
      font-family: $Font_PlayfairDisplay;
      @include sm {
        font-size: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
      }
      @include xs {
        font-size: 24px;
      }
    }
    .login-head-arabic {
      width: 460px;
      left: 444px;
      top: 192px;
      font-family: $Font_NotoNaskhArabicRegular;
      font-size: 46px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      text-align: right;
      @include md {
        width: 350px;
        height: 38px;
        text-align: center;
        margin-bottom: 28px;
        font-size: 28px;
      }
      @include sm {
        width: 350px;
        height: 38px;
        text-align: center;
        margin-bottom: 28px;
        font-size: 28px;
      }
      @include xs {
        width: 250px;
        height: 48px;
        text-align: center;
        margin-bottom: 28px;
        font-size: 28px;
      }
    }
    .login-head-pn {
      @extend .login-head;
      font-size: 36px;
      margin-bottom: 0px;
      font-family: $Font_PlayfairDisplay;
      @include sm {
        font-size: 28px;
        justify-content: flex-start;
      }
      @include xs {
        font-size: 24px;
      }
    }
    .login-head-pn-arabic {
      @extend .login-head;
      margin-bottom: 0px;
      font-family: $Font_NotoNaskhArabicRegular;
      font-size: 38px;
      line-height: 120%;
      text-align: right;
      @include sm {
        font-size: 28px;
        justify-content: flex-start;
      }
      @include xs {
        font-size: 24px;
      }
    }
    .login-head-pd {
      @extend .login-head-pn;
      margin-bottom: 2.7%;
    }
    .login-head-pd-arabic {
      @extend .login-head-pn-arabic;
      margin-bottom: 2.7%;
    }
    .btnbox {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .btnbox-pn {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 20px;
      @include sm {
        position: absolute;
        bottom: 70px;
        width: 100%;
      }
    }
    .btnbox-pn-amber {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;
      @include sm {
        position: relative;
        bottom: 0px;
        width: 100%;
        padding: 20px;
      }
    }
    .btnbox-pnx {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include sm {
        position: relative;
        top: 4.8%;
        width: 100%;
      }
    }
    .btnbox-multiple-email {
      position: relative;
      top: 46px;
      @include sm {
        position: relative;
        top: 24px;
        width: 100%;
      }
    }
    .btnbox-login-email {
      position: relative;
      top: 0px;
      width: 100%;
      max-width: 288px;
      @include sm {
        position: relative;
        top: 0px;
        width: 100%;
        max-width: 100%;
      }
    }
    .btnbox-login-select {
      position: relative;
      top: 0px;
      margin-top: 54px;
      width: 100%;
      max-width: 288px;
      @include sm {
        position: relative;
        top: 0px;
        width: 100%;
        max-width: 100%;
        margin-top: 32px;
      }
    }
    .btnbox-pd {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      bottom: 4.2%;
      @include md {
        position: absolute;
        bottom: 4.2%;
        width: 100%;
        justify-content: center;
      }
      @include xs {
        position: absolute;
        bottom: 1.2%;
      }
    }
    .textall {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
    }
    .button-submit {
      font-family: $Font_Montserrat;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      width: 460px;
      min-height: 42px;
      background: #000000;
      border-radius: 34px;
      color: #ffffff;
      cursor: pointer;
      @include sm {
        width: 100%;
        max-width: 100%;
      }
    }
    .button-submit-pn {
      @extend .button-submit;
      width: 288px;
      margin-top: 47px;
      @include sm {
        width: 100%;
        max-width: 100%;
      }
    }
    .button-submit-pd {
      @extend .button-submit;
      width: 338px;
      margin-top: 47px;
      @include sm {
        width: 100%;
        max-width: 350px;
      }
    }
    .button-submit-login {
      @extend .button-submit-pn;
      padding: 0 !important;
      width: 100%;
      max-width: 424px !important;
    }
    .button-submit-login-amber {
      @extend .button-submit-pn;
      padding: 0 !important;
      width: 100%;
      max-width: 100% !important;
    }
    .button-submit-login-WA {
      @extend .button-submit-login;
      width: 100%;
      max-width: 477px;
    }
    .subtext {
      font-family: $Font_Montserrat;
      margin: 24px 24px 0px 0px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      color: #5a5f64;
      @include sm {
        max-width: 350px;
        margin: 24px 0px 0px 0px;
        padding-left: 10px;
      }
    }
    .subtext-arabic {
      font-family: $Font_TajawalRegular;
      font-style: normal;
      font-weight: 400;
      font-size: 19px;
      line-height: 130%;
      text-align: right;
      color: #5a5f64;
      margin-top: 8px;
      margin-bottom: 40px;
      direction: rtl;

      @include sm {
        max-width: 350px;

        padding-left: 10px;
      }
    }
    .subtext-pn {
      @extend .subtext;
      margin: 12px 12px 40px 0px;
      font-family: $Font_Montserrat;
      width: 100%;
      max-width: 450px;
      color: #000000;
      @include sm {
        max-width: 350px;
        margin: 12px 0px 40px 0px;
        color: #000000;
      }
    }
    .subtext-pn-arabic {
      @extend .subtext-arabic;
      margin: 12px 12px 40px 0px;
      width: 100%;
      max-width: 450px;
      color: #000000;
      @include sm {
        max-width: 350px;
        margin: 12px 0px 40px 0px;
        color: #000000;
      }
    }
    .subtext-multiple {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      margin: 12px 12px 40px 0px;
      font-family: $Font_Montserrat;
      width: 100%;
      max-width: 450px;
      color: #000000;
      @include sm {
        max-width: 350px;
        margin: 12px 0px 40px 0px;
        color: #000000;
      }
    }
    .subtext-multiple-arabic {
      font-family: $Font_TajawalRegular;
      font-style: normal;
      font-weight: 400;
      font-size: 19px;
      line-height: 130%;
      margin: 12px 12px 40px 0px;
      width: 100%;
      max-width: 450px;
      color: #000000;
      @include sm {
        max-width: 350px;
        margin: 12px 0px 40px 0px;
        color: #000000;
      }
    }
    .subtext-lg {
      @extend .subtext;
      margin: 8px 12px 40px 0px;
      font-family: $Font_Montserrat;
      font-size: 16px;
      line-height: 24px;
      color: #000000;

      @include sm {
        max-width: 338px;
        margin: 12px 0px 40px 0px;
        padding-left: 0px;
        color: #000000;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .subtext-lg-otp {
      @extend .subtext;
      margin: 8px 8px 40px 0px;
      font-family: $Font_Montserrat;
      font-size: 16px;
      line-height: 24px;
      color: #000000;

      @include sm {
        max-width: 338px;
        margin: 12px 0px 40px 0px;
        padding-left: 0px;
        color: #000000;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .sublink {
      font-family: $Font_Montserrat;
      position: relative;
      top: 24px;
      color: #000000;
      text-decoration: none;
      font-size: 14px;
      @include sm {
        padding-left: 10px;
      }
    }

    a {
      color: #000000;
    }
    .phone-text {
      font-family: $Font_Montserrat_Medium;
      font-style: normal;
      font-weight: 500 !important;
      font-size: 14px;
      line-height: 130%;
      color: #000000;
    }
    .phone-text-arabic {
      font-family: $Font_TajawalMedium;
      font-style: normal;
      font-weight: 500 !important;
      font-size: 14px;
      line-height: 130%;
      color: #000000;
    }
    .phone-text-sign {
      font-family: $Font_Montserrat;
      font-style: normal;
      font-weight: 400 !important;
      font-size: 14px;
      line-height: 130%;
      color: #000000;
    }
    .phone-text-pd {
      @extend .phone-text;
      margin-bottom: 8px;
    }
  }
  .left-page-form-pn {
    @extend .left-page-form;

    @include sm {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      top: 123px;
      max-width: 338px;
    }
    @include xs {
      max-width: 230px;
    }
  }
  .left-page-form-pd {
    @extend .left-page-form-pn;
    form {
      padding-bottom: 20px;
    }
    top: 215px;
    height: calc(100% - 212px);
    @include sm {
      top: 123px;
      height: calc(100% - 123px);
      max-width: calc(100% - 40px);
    }

    @include xs {
      top: 123px;
      height: calc(100% - 123px);
      max-width: calc(100% - 40px);
    }
  }

  .left-page-footer {
    position: relative;
    bottom: 88px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include sm {
      bottom: 64px;
    }
  }
  .footer-text {
    font-family: $Font_Montserrat;
    margin: 16px 0px 0px 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5a5f64;
    text-align: center;
    @include sm {
      max-width: 350px;
      padding-left: 10px;
    }
  }
  .close-button {
    position: absolute !important;
    left: 41% !important;
    top: 25px !important;
    @include xl {
      left: 91% !important;
    }
    @include sm {
      left: 85% !important;
    }
  }
  .return-button {
    position: absolute !important;
    left: 1% !important;
    top: 25px !important;
  }
  .otp-time-text {
    margin-top: 8px;
    font-family: $Font_Montserrat !important;
    font-size: 14px;
    @include sm {
      margin-top: 20px;
    }
  }
  .otp-time-text-arabic {
    margin-top: 8px;
    font-family: $Font_TajawalRegular !important;
    font-size: 14px;
    @include sm {
      padding-top: 20px;
    }
  }
  .login-otp-time-text {
    margin-top: 24px;
    font-family: $Font_Montserrat !important;
    font-size: 14px;
    @include sm {
      margin-top: 20px;
    }
  }
  .login-otp-time-text-arabic {
    margin-top: 24px;
    font-family: $Font_TajawalRegular !important;
    font-size: 14px;
    @include sm {
      padding-top: 20px;
    }
  }
  .textfont {
    font-family: $Font_Montserrat !important;
  }
  .textfont-pd {
    font-family: $Font_Montserrat !important;
    margin-top: 2.7%;
  }
  .custom-input {
    padding: 9.5px !important;
  }
  .datetext {
    font-family: $Font_Montserrat !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5a5f64;
    height: 20px;
  }
  .datetext-sub {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    margin-top: 18px;
    color: #5a5f64;
    display: flex;
    align-items: center;
    height: 16px;
    @include md {
      height: 3vh;
    }
    @include sm {
      height: 30px;
    }
  }
  .textdisplay {
    display: flex;
    justify-content: center;
    flex-direction: column;
    @include md {
      height: 100%;
      min-height: 22vh;
    }
    @include xs {
      height: 100%;
      min-height: 5vh;
    }
  }

  .selecttext {
    font-family: $Font_Montserrat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-top: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .selecttext-arabic {
    font-family: $Font_TajawalRegular;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
  }
  .SC-text {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
    padding-left: 8px;
  }
  .SC-text-arabic {
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
    padding-right: 8px;
  }
  .custom-radios {
    display: flex !important;
    flex-direction: column;
    margin-top: 24px;
    div {
      display: inline-block;
    }
    .SC-radio-box {
      font-family: $Font_Montserrat;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px 0px 15px 0px;
      font-size: 14px;
    }

    .SC-box-1 {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .SC-box-2 {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    input[type="radio"] {
      display: none;
      + label {
        color: #333;
        font-family: Arial, sans-serif;
        font-size: 14px;
        span {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin: 0;
          vertical-align: middle;
          cursor: pointer;
          border-radius: 50%;
          border: 1px solid #ced0d7;
          background-repeat: no-repeat;
          background-position: center;
          text-align: center;
        }
      }
    }
  }
  .custom-radios-arabic {
    display: flex !important;
    flex-direction: column;
    margin-top: 24px;
    div {
      display: inline-block;
    }
    .SC-radio-box {
      font-family: $Font_Montserrat;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px 0px 15px 0px;
      font-size: 14px;
    }

    .SC-box-1 {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .SC-box-2 {
      width: 50%;
      display: flex;
      justify-content: flex-end;
    }
    input[type="radio"] {
      display: none;
      + label {
        color: #333;
        font-family: Arial, sans-serif;
        font-size: 14px;
        span {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin: 0;
          vertical-align: middle;
          cursor: pointer;
          border-radius: 50%;
          border: 1px solid #ced0d7;
          background-repeat: no-repeat;
          background-position: center;
          text-align: center;
          img {
            opacity: 0;
            transition: all 0.3s ease;
          }
        }
      }

      &#color-1 + label span {
        background-color: white;
      }

      &#color-2 + label span {
        background-color: white;
      }

      &#color-3 + label span {
        background-color: white;
      }

      &#color-4 + label span {
        background-color: white;
      }
      &#color-5 + label span {
        background-color: white;
      }
      &#color-6 + label span {
        background-color: white;
      }
      &:checked + label span img {
        opacity: 1;
        width: 10px;
      }
      &:checked + label span {
        opacity: 1;
        background-color: black !important;
      }
    }
  }
  .SCcountry-text {
    width: 460px;
    height: 16px;
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-decoration-line: underline;
    color: #5a5f64 !important;
    margin-top: 22px;
  }
  .scroll-dialog-title {
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 120%;
  }

  .loginLP {
    max-width: 460px;
    height: 100%;
    width: calc(100% - 96px);
    position: relative;
    top: 0%;
    margin-top: 93px;
    @include md {
      width: calc(100% - 40px);
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 120px;
    }
  }
  .button-box-lg {
    display: flex;
    justify-content: center !important;
    bottom: 50px;
    flex-direction: column;
    margin-top: 25px;
    @include sm {
      position: relative;
      display: flex;
      justify-content: center !important;
      bottom: 50px;
      width: 100%;
      flex-direction: column;
      align-items: center;
    }
  }
  .button-box-lg-amber {
    display: flex;
    justify-content: center !important;
    bottom: 0px;
    flex-direction: column;
    margin-top: 24px;
    @include sm {
      position: relative;
      display: flex;
      justify-content: center !important;
      bottom: 0px;
      width: 100%;
      flex-direction: column;
      align-items: center;
    }
  }
  .login-page-main {
    width: 100%;
    height: 100%;
    max-height: 508px;
    max-width: 520px;
    position: relative;
    @include md {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
    }
    &[data-path="loginPhone"] {
      height: initial;
      min-height: 490px;
      @include md {
        max-height: 100%;
        max-width: 100%;
        height: 100%;
      }
    }
  }
  .LGmessage {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5a5f64;
    text-align: center;
  }
  .LGmessage-arabic {
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5a5f64;
    text-align: center;
  }
  .custom-radio-row {
    box-sizing: border-box;
    margin: 8px 0px 8px 0px;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    padding: 16px 5px;
    gap: 8px;
    height: 52px;
    background: #ffffff;
    border: 1px solid #dfe1ea;
    border-radius: 4px;
    cursor: pointer;
  }
  .custom-radio-row-active {
    box-sizing: border-box;
    margin: 8px 0px 8px 0px;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    padding: 16px 5px;
    gap: 8px;
    height: 52px;
    background: #ffffff;
    border: 1px solid #5a5f64;
    border-radius: 4px;
    cursor: pointer;
  }
}
.WPloginWithAmber {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  align-content: center;
  background-color: #ffffff;
  @include md {
    background-color: #ffffff;
  }
  .login-head {
    width: 460px;
    height: 43px;
    left: 444px;
    top: 192px;
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 120%;
    text-align: left;
    @include md {
      width: 350px;
      height: 38px;
      text-align: center;
      margin-bottom: 28px;
      font-size: 28px;
    }
    @include sm {
      width: 350px;
      height: 38px;
      text-align: center;
      margin-bottom: 28px;
      font-size: 28px;
    }
    @include xs {
      width: 250px;
      height: 48px;
      text-align: center;
      margin-bottom: 28px;
      font-size: 28px;
    }
  }
  .login-head-arabic {
    width: 460px;
    height: 43px;
    left: 444px;
    top: 192px;
    font-family: $Font_NotoNaskhArabicRegular;
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-align: right;
    @include md {
      width: 350px;
      height: 38px;
      text-align: center;
      margin-bottom: 28px;
      font-size: 28px;
    }
    @include sm {
      width: 350px;
      height: 38px;
      text-align: center;
      margin-bottom: 28px;
      font-size: 28px;
    }
    @include xs {
      width: 250px;
      height: 48px;
      text-align: center;
      margin-bottom: 28px;
      font-size: 28px;
    }
  }
  .login-head-WA {
    width: 553px;
    height: auto;
    left: 444px;
    top: 192px;
    text-align: center;
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 120%;
    @include md {
      width: 350px;
      height: 68px;
      font-size: 28px;
    }
    @include sm {
      width: 350px;
      height: 68px;
      font-size: 20px;
    }
    @include xs {
      width: 250px;
      height: 48px;
      font-size: 20px;
    }
  }
  .login-head-WA-arabic {
    width: 553px;
    height: auto;
    left: 444px;
    top: 192px;
    text-align: center;
    font-family: $Font_NotoNaskhArabicRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 120%;
    @include md {
      width: 350px;
      height: 68px;
      font-size: 28px;
    }
    @include sm {
      width: 350px;
      height: 68px;
      font-size: 20px;
    }
    @include xs {
      width: 250px;
      height: 48px;
      font-size: 20px;
    }
  }
  .left-page-form-pn {
    @extend .left-page-form;
    @include sm {
      top: 123px;
      max-width: 338px;
    }
    @include xs {
      max-width: 230px;
    }
  }
  .left-page-form-pn-arabic {
    @extend .left-page-form;
    font-family: $Font_NotoNaskhArabicRegular;
    @include sm {
      top: 123px;
      max-width: 338px;
    }
    @include xs {
      max-width: 230px;
    }
  }
  .loginLP {
    max-width: 460px;
    width: calc(100% - 96px);
    height: 100%;
    position: relative;
    top: 0%;
    margin-top: 64px;
    @include md {
      width: calc(100% - 40px);
    }
  }
  label {
    top: -5px !important;
  }
  .MuiOutlinedInput-root {
    padding-right: 5px !important;
  }
  .MuiInputLabel-root {
    font-size: 0.86rem !important;
  }
  .MuiFormHelperText-root {
    color: #f66464 !important;
    margin-left: 0px !important;
    font-size: 14px !important;
    line-height: 130% !important;
    margin-top: 8px !important;
  }
  .WPleftpage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #ffffff;
    height: 100%;
    width: $WPleftpageWidth;
    @include xl {
      width: 100%;
    }
  }
  .left-page-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    top: 14.7%;
    position: relative;
    @include sm {
      top: 50px;
    }
  }
  .phone-text {
    font-family: $Font_Montserrat_Medium;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 130%;
    color: #000000;
    margin-top: 40px;
    margin-bottom: 16px;
  }
  .phone-text-arabic {
    font-family: $Font_TajawalMedium;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 130%;
    color: #000000;
    margin-top: 40px;
    margin-bottom: 16px;
  }
  .phone-text-pd {
    @extend .phone-text;
    margin-bottom: 8px;
  }
  .button-submit {
    font-family: $Font_Montserrat;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    font-size: 14px;
    width: 460px;
    height: 42px;
    background: #000000;
    border-radius: 34px;
    color: #ffffff;
    cursor: pointer;
    @include sm {
      width: 100%;
      max-width: calc(100% - 40px);
    }
  }
  .button-submit-pn {
    @extend .button-submit;
    width: 288px;
    margin-top: 47px;
    @include sm {
      width: 100%;
      max-width: calc(100% - 40px);
    }
  }
  .button-submit-pd {
    @extend .button-submit;
    width: 338px;
    margin-top: 47px;
    @include sm {
      width: 100%;
      max-width: 350px;
    }
  }
  .button-submit-login {
    @extend .button-submit-pn;
    width: 100%;
    max-width: 424px !important;
  }
  .button-submit-login-WA {
    @extend .button-submit-login;
    width: 100%;
    max-width: 477px !important;
  }
  .subtext {
    font-family: $Font_Montserrat;
    margin: 24px 24px 0px 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5a5f64;
    @include sm {
      max-width: 350px;
      margin: 24px 0px 0px 0px;
      padding-left: 10px;
    }
  }
  .subtext-pn {
    @extend .subtext;
    margin: 12px 12px 40px 0px;
    font-family: $Font_Montserrat;
    width: 100%;
    max-width: 346px;
    color: #000000;
    font-size: 16px;
    line-height: 130%;
    @include sm {
      max-width: 350px;
      margin: 12px 0px 40px 0px;
      padding-left: 0px;
      color: #000000;
    }
  }
  .subtext-lg {
    @extend .subtext;
    margin: 8px 12px 40px 0px;
    font-family: $Font_Montserrat;
  }
  .subtext-arabic {
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 130%;
    text-align: right;
    color: #5a5f64;
    margin-top: 8px;
    direction: ltr;
    @include sm {
      max-width: 350px;
    }
  }
  .sublink {
    font-family: $Font_Montserrat;
    position: relative;
    top: 24px;
    color: #000000;
    text-decoration: none;
    font-size: 14px;
    @include sm {
      padding-left: 10px;
    }
  }

  a {
    color: #000000;
  }
  .otp-time-text {
    margin-top: 8px;
    font-family: $Font_Montserrat !important;
    font-size: 14px;
    @include sm {
      margin-top: 20px;
    }
  }
  .otp-time-text-arabic {
    margin-top: 8px;
    font-family: $Font_TajawalRegular !important;
    font-size: 14px;
    @include sm {
      margin-top: 20px;
    }
  }
}
.count-class {
  cursor: pointer;
  text-decoration: underline;
}
.log-with-amber {
  background-color: #ffffff !important;
  height: calc(100vh - 4px) !important;
  align-items: flex-start !important;
}
.log-with-amber-main {
  max-height: 100vh !important;
  height: 100% !important;
  max-width: 100% !important;
}

.log-with-amber-loginlp {
  margin-top: 0px !important;
  max-width: 553px !important;
}
.log-with-amber-loginlp2 {
  margin-top: 0px !important;
  max-width: 463px !important;
}
.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.f-26 {
  font-size: 36px !important;
  @include sm {
    font-size: 26px !important;
  }
}
