@import "./variables";
@import "./breakpoints";
.events-carousal-text {
  font-family: $Font_PlayfairDisplay;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 120%;
  color: #000000;
  margin-bottom: 12px;
  position: absolute;
  top: 0px;
  @include md {
    margin-bottom: 20px;
  }
}
.box-16 {
  width: 16px;
}
.events {
  .swiper {
    height: 100%;
    min-height: 100%;

    .swiper-wrapper {
      margin-top: 0px;
    }

    .swiper-pagination {
      margin-top: 20px;
      position: relative;
      bottom: 0;
    }
    .swiper-pagination-bullet {
      border-radius: 0%;
      width: 56px;
      height: 2px;
      background: #ced0d7;
      margin-bottom: 2%;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background: #000000;
      bottom: 20px;
      margin-bottom: 2%;
    }
    .swiper-button-prev {
      right: 60px !important;
      left: auto !important;
      top: 10px !important;
      color: #000000;
      @include xxl {
        top: 10px !important;
      }
      @include xl {
        top: 10px !important;
      }
      @include md {
        top: 10px !important;
      }
      @include sm {
        top: 10px !important;
      }
    }

    .swiper-button-next {
      right: 20px !important;
      left: auto !important;
      top: 10px !important;
      color: #000000;
      @include xxl {
        top: 10px !important;
      }
      @include xl {
        top: 10px !important;
      }
      @include md {
        top: 10px !important;
      }
      @include sm {
        top: 10px !important;
      }
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
      font-size: 14px;
      text-transform: none !important;
      letter-spacing: 0;
      font-variant: initial;
      line-height: 1;
    }
    .swiper-button-next,
    .swiper-button-prev {
      width: 7px;
    }
    .section-text-head {
      height: 18px;
      font-family: $Font_ArchivoBlack;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #000000;
      text-align: center;
      margin-bottom: 8px;
      @include sm {
        margin-bottom: 16px;
      }
    }
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      top: var(--swiper-slide);
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .swiper-text-brands {
      height: 12px;
      font-family: $Font_ArchivoBlack;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #000000;
    }
    .swiper-text-brands-arabic {
      text-align: right;
    }
  }
  .card-text-events {
    min-height: 21px;
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .card-text-events-arabic {
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 130%;
    text-align: right;
    color: #000000;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
.card-text-categories-events {
  font-family: $Font_Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #000000;
  margin-top: 8px;
}
.card-text-categories-editorial {
  font-family: $Font_Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #000000;
  margin-top: 20px;
}
.card-text-categories-events-arabic {
  font-family: $Font_TajawalRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  text-align: right;
  color: #000000;
  height: auto;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 8px;
}
.card-text-categories-editorial-arabic {
  line-height: 130%;
  color: #000000;
  margin-top: 20px;
  font-family: $Font_TajawalRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  text-align: right;
}
.BrandHighlight {
  max-width: -webkit-fill-available;
}
.BrandHighlightPromotion {
  max-width: -webkit-fill-available;
}
.BrandHighlightPr {
  max-width: -webkit-fill-available;
}
.BrandHighlightMobile {
  max-width: -webkit-fill-available;
}
.location-tab {
  width: calc(100%) !important;
}

.event-card {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0;
    margin: 0;
  }
  $body-bgc: white;
  $card-color: #e7f0df;
  max-width: 720px;
  width: 100%;
  .scooped-card {
    max-width: 640px;
    position: relative;
    width: calc(100% - 80px);
    padding: 40px;
    display: block;

    .content-container {
      display: flex;
      justify-content: space-between;
      @include sm {
        flex-direction: column;
      }
    }
    .from-text {
      font-family: $Font_Montserrat;
      font-size: 12px;
      font-weight: 400;
      line-height: 15.6px;
      margin-bottom: 4px;
    }
    .from-value {
      font-family: $Font_ArchivoBlack;
      font-size: 10px;
      line-height: 12px;
      margin-bottom: 8px;
      text-transform: uppercase;
      letter-spacing: 0.2em;
    }
    .title {
      font-family: $Font_PlayfairDisplay;
      font-size: 28px;
      font-weight: 400;
      line-height: 120%;
      color: #000000;
    }
    .date {
      font-family: $Font_Montserrat_Medium;
      font-size: 14px;
      font-weight: 500;
      line-height: 18.2px;
    }
    .location {
      font-family: $Font_Montserrat_Medium;
      font-size: 14px;
      font-weight: 500;
      line-height: 18.2px;
    }
    .address {
      font-family: $Font_Montserrat;
      font-size: 14px;
      font-weight: 400;
      line-height: 18.2px;
      margin-bottom: 24px;
      color: #5a5f64;
    }
    .terms-conditions-text {
      font-family: $Font_Montserrat;
      font-size: 12px;
      font-weight: 400;
      line-height: 15.6px;
      letter-spacing: 0em;

      @include sm {
        margin-bottom: 24px;
      }
    }
    .terms-pointer {
      cursor: pointer;
      text-decoration: underline;
      margin-top: 8px;
    }
    &[data-lang="arabic"] {
      .from-value {
        font-family: $Font_ArchivoBlack;
        font-size: 10px;
        line-height: 12px;
        margin-bottom: 8px;
        text-transform: uppercase;
        letter-spacing: 0em;
      }
      .from-text {
        font-family: $Font_TajawalRegular;
        font-size: 15px;
        line-height: 19.5px;
      }
      .title {
        font-family: $Font_NotoNaskhArabicRegular;
        font-size: 28px;
        line-height: 36px;
      }
      .location {
        font-family: $Font_TajawalMedium;
        font-size: 17px;
        font-weight: 500;
        line-height: 22.1px;
      }
      .address {
        font-family: $Font_TajawalRegular;
        font-size: 17px;
        font-weight: 400;
        line-height: 22.1px;
      }
      .date {
        font-family: $Font_TajawalRegular;
        font-size: 14px;
        font-weight: 400;
        line-height: 22.1px;
      }
      .terms-conditions-text {
        font-family: $Font_TajawalRegular;
        font-size: 15px;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: 0em;
      }
    }
  }

  .scooped-card:before,
  .scooped-card:after,
  p:before,
  p:after {
    content: "";
    display: block;
    width: 2rem;
    height: 2rem;
    background-color: white;
    position: absolute;
  }
  .scooped-card:before {
    top: 0;
    left: 0;
    border-bottom-right-radius: 100%;
  }
  .scooped-card:after {
    bottom: 0;
    right: 0;
    border-top-left-radius: 100%;
  }
  p:before {
    top: 0;
    right: 0;
    border-bottom-left-radius: 100%;
  }
  p:after {
    bottom: 0;
    left: 0;
    border-top-right-radius: 100%;
  }
}
