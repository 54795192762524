@import "./variables";
@import "./breakpoints";

.financial-services {
  width: 100%;
  height: 100%;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  .summary-header {
    color: white;
    .description {
      font-family: $Font_Montserrat;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
    }
    .statistics-container {
      margin-top: 32px;
      .statistics-value {
        font-family: $Font_PlayfairDisplay;
        font-size: 72px;
        font-weight: 400;
        line-height: 86px;
        color: #799ad4;
      }
      .statistics-description {
        font-family: $Font_Montserrat;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
      }
    }
  }
  .leverage {
    .slogan {
      font-family: $Font_ArchivoBlack;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2em;
    }
    .title {
      //styleName: Web Typography/H2;
      font-family: $Font_PlayfairDisplay;
      font-size: 54px;
      font-weight: 400;
      line-height: 65px;
    }
    .description {
      font-family: $Font_Montserrat;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
    }
  }
}
