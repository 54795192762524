@import "./variables";
@import "./breakpoints";
.brand-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 12px;
  font-family: $Font_Montserrat;
  width: 160px;
  height: 36px;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: #000000;
  border: 1px solid #5a5f64;
  border-radius: 34px;
  background-color: #fff;
  cursor: pointer;
  margin-bottom: 48px;
}
.brand-button-arabic {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 12px;
  font-family: $Font_TajawalRegular;
  width: 160px;
  height: 36px;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #000000;
  border: 1px solid #5a5f64;
  border-radius: 34px;
  background-color: #fff;
  cursor: pointer;
  margin-bottom: 48px;
}
.button-group {
  display: flex;
  overflow-x: auto;
}

.button-group-btn {
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  height: 30px;
  border: none;
  background: #f3f3fa;
  border-radius: 80px;
  font-family: $Font_Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #000000;
  z-index: 10;
  padding: 8px 16px;
  margin-right: 8px;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
  }
}
.button-group-btn-arabic {
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  height: 30px;
  border: none;
  background: #f3f3fa;
  border-radius: 80px;
  font-family: $Font_TajawalRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #000000;
  z-index: 10;
  padding: 8px 16px;
  margin-right: 8px;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
  }
}
.button-group-btn-active {
  color: #f3f3fa;
  background: #000000;
}
.brand-store-btngroup {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
.button-group-btn-disable {
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  height: 30px;
  border: none;
  background: #f3f3fa;
  border-radius: 80px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  z-index: 10;
  padding: 8px 16px;
  margin-right: 8px;
  white-space: nowrap;
  color: #ced0d7 !important;
  &:hover {
    cursor: pointer;
  }
}
.brand-carousel-image {
  max-height: 5000px;
}
.brand-head-tiny-text {
  height: 18px;
  font-family: $Font_Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  margin-top: 8px;
  text-align: left;
  width: 100%;
}
.brand-head-tiny-text-arabic {
  height: 22px;
  font-family: $Font_TajawalRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;
  text-align: right;
  color: #000000;
  margin-top: 8px;
  width: 100%;
}
.barnd-head-details {
  font-family: $Font_PlayfairDisplay;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 120%;
  color: #000000;
  width: 100%;
}
.barnd-head-details-arabic {
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 130%;
  text-align: right;
  font-family: $Font_NotoNaskhArabicRegular;
  color: #000000;
  width: 100%;
  @include md {
    font-size: 36px;
  }
}
.brands-left-text {
  max-width: 511px;
  width: 100%;
  font-family: $Font_PlayfairDisplay;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  color: #000000;
  padding-right: 20px;
}
.brands-left-text-arabic {
  max-width: 360px;
  width: 100%;
  font-family: $Font_NotoNaskhArabicRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 29px;
  line-height: 150%;
  text-align: right;
  color: #000000;
}
.brands-right-text {
  max-width: 1040px;
  width: 100%;
  font-family: $Font_Montserrat;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
  font-style: normal;
  padding-top: 0px;
  text-align: left;
  white-space: break-spaces;
}
.brands-right-text-arabic {
  max-width: 1040px;
  width: 100%;
  font-family: $Font_TajawalRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  padding-top: 0px;
  text-align: right;
  white-space: break-spaces;
  @include lg {
    margin-top: 48px;
  }
  @include md {
    margin-top: 48px;
    padding-left: 0px;
  }
}
.img-tick {
  background-color: #000000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 20px;
  height: 20px;
}
.tick-number {
  font-family: $Font_Montserrat;
  font-size: 10px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.tick-text {
  font-family: $Font_Montserrat_Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #000000;
}
.tick-text-arabic {
  font-family: $Font_TajawalMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #000000;
}
.brands-deatil-head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}
.location-text {
  font-family: $Font_ArchivoBlack;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #000000;
  padding-left: 0px;
}
.location-text-arabic {
  font-family: $Font_TajawalBold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 110%;
  text-align: right;
  letter-spacing: 0.02em;
  color: #000000;
  text-transform: uppercase;
  padding-left: 0px;
}
.location-button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 6px 12px;
  gap: 10px;
  width: 159px;
  height: 30px;
  right: -1px;
  border: 1px solid #000000;
  color: #000000 !important;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  font-family: $Font_Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}
.brand-details-image {
  position: relative;
  right: 0px;
}
.brand-details-head {
  height: 14px;
  font-family: $Font_ArchivoBlack;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #000000;
}
.brand-details-head-arabic {
  font-family: $Font_TajawalBold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 110%;
  height: 18px;
  text-align: right;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
}
.brand-details-text {
  font-family: $Font_Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #000000;
  margin-top: 12px;
  @include md {
    margin-top: 8px;
  }
}
.brand-details-date {
  font-family: $Font_Montserrat_Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  margin-top: 12px;
  @include md {
    margin-top: 8px;
  }
  color: #000000;
}
.brand-details-date-arabic {
  font-family: $Font_TajawalMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 130%;
  margin-top: 12px;
  @include md {
    margin-top: 8px;
  }
  color: #000000;
}
.brand-details-text-arabic {
  font-family: $Font_TajawalRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;
  color: #000000;
  margin-top: 12px;
  @include md {
    margin-top: 8px;
  }
}
.brands {
  .swiper {
    height: 100%;
    min-height: var(--carousalSubHeight);
    @include _3xl {
      margin-top: var(--swiper);
      height: 100%;
      min-height: 500px;
    }
    @include xxl {
      margin-top: var(--swiper);
      height: 100%;
      min-height: 450px;
    }
    @include xl {
      margin-top: var(--swiper);
      height: 100%;
      min-height: 400px;
    }
    @include lg {
      margin-top: var(--swiper);
      height: 100%;
      min-height: var(--carousalSubHeightmobile);
    }
    @include md {
      margin-top: var(--swiper);
      min-height: var(--carousalSubHeightmobileSM);
      height: 100%;
    }
    @include sm {
      margin-top: var(--swiper);
      min-height: 250px;
      height: 100%;
    }

    .swiper-wrapper {
      margin-top: var(--swiper-wrapper);
    }
    .swiper-pagination {
      position: relative;
      bottom: 25px;
    }

    .swiper-pagination-bullet-active {
      background: #000000;
      bottom: 20px;
      margin-bottom: 2%;
    }
    .swiper-button-prev {
      right: 60px !important;
      left: auto !important;
      top: 10px !important;
      color: #000000;
      @include xxl {
        top: 10px !important;
      }
      @include xl {
        top: 10px !important;
      }
      @include md {
        top: 10px !important;
      }
      @include sm {
        top: 10px !important;
      }
    }

    .swiper-button-next {
      right: 20px !important;
      left: auto !important;
      top: 10px !important;
      color: #000000;
      @include xxl {
        top: 10px !important;
      }
      @include xl {
        top: 10px !important;
      }
      @include md {
        top: 10px !important;
      }
      @include sm {
        top: 10px !important;
      }
    }
    .swiper-pagination-bullet {
      border-radius: 0%;
      width: 56px;
      height: 2px;
      background: #ced0d7;
      opacity: 1;
      margin-bottom: 2%;
      cursor: pointer;
    }

    .swiper-pagination-bullet-active {
      background: #ffffff;
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
      font-size: 14px;
      text-transform: none !important;
      letter-spacing: 0;
      font-variant: initial;
      line-height: 1;
    }
    .swiper-button-next,
    .swiper-button-prev {
      width: 7px;
    }
    .section-text-head {
      height: 18px;
      font-family: $Font_ArchivoBlack;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #000000;
      text-align: center;
      margin-bottom: 8px;
      @include sm {
        margin-bottom: 16px;
      }
    }
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      top: var(--swiper-slide);
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .swiper-text-brands {
      height: 12px;
      font-family: $Font_ArchivoBlack;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #000000;
    }
  }
  .location-tab {
    width: calc(100% - 15px) !important;
  }
}
.brandsDetailCarouselContainer {
  .brands-detail {
    @include sm {
      min-height: unset !important;
    }
  }
}
.brands-detail {
  .swiper {
    height: 100%;
    .swiper-wrapper {
      margin-top: 0;
    }
    .swiper-pagination {
      position: absolute;
      bottom: 0;
      margin: 0;
      @include md {
        bottom: 10px;
      }
    }

    .swiper-button-prev {
      right: 60px !important;
      left: auto !important;
      top: 10px !important;
      color: #000000;
      @include xxl {
        top: 10px !important;
      }
      @include xl {
        top: 10px !important;
      }
      @include md {
        top: 10px !important;
      }
      @include sm {
        top: 10px !important;
      }
    }

    .swiper-button-next {
      right: 20px !important;
      left: auto !important;
      top: 10px !important;
      color: #000000;
      @include xxl {
        top: 10px !important;
      }
      @include xl {
        top: 10px !important;
      }
      @include md {
        top: 10px !important;
      }
      @include sm {
        top: 10px !important;
      }
    }
    .swiper-pagination-bullet {
      border-radius: 0%;
      width: 56px;
      height: 2px;
      background: #5a5f64;
      opacity: 1;
      margin-bottom: 2%;
      cursor: pointer;
    }

    .swiper-pagination-bullet-active {
      background: #ffffff;
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
      font-size: 14px;
      text-transform: none !important;
      letter-spacing: 0;
      font-variant: initial;
      line-height: 1;
    }
    .swiper-button-next,
    .swiper-button-prev {
      width: 7px;
    }
    .section-text-head {
      height: 18px;
      font-family: $Font_ArchivoBlack;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #000000;
      text-align: center;
      margin-bottom: 8px;
      @include sm {
        margin-bottom: 16px;
      }
    }
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      top: 0px;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .swiper-text-brands {
      height: 12px;
      font-family: $Font_ArchivoBlack;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #000000;
    }
  }
}
