@import "./variables";
@import "./breakpoints";

.account-settings {
  display: flex !important;
  flex-wrap: nowrap !important;
  background-color: white;
  align-items: flex-start;
  width: 556px;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 100px;
  padding-bottom: 80px;
  height: 100%;
  @include sm {
    width: 100% !important;
    flex-direction: column !important;
    padding-left: 20px;
    padding-right: 20px;
    max-height: 100%;
    padding-top: 24px;
    padding-bottom: 48px;
  }
  .account-settings-menu {
    width: 273px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 22px;
    @include sm {
      width: 100% !important;
    }
    .menu-item-text {
      font-family: $Font_Montserrat;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      color: #5a5f64;
      cursor: pointer;

      &[data-is-selected="true"] {
        font-family: $Font_Montserrat_Medium;
        font-size: 16px;
        line-height: 20.8px;
      }
      &[data-lang="arabic"] {
        font-family: $Font_TajawalRegular;
        font-size: 19px;
        line-height: 24.7px;

        &[data-is-selected="true"] {
          font-family: $Font_TajawalMedium;
          font-size: 19px;
          line-height: 24.7px;
        }
      }
    }
  }

  .form-settings-wrapper-spacer {
    width: 273px;
    @include sm {
      width: 0px;
      display: none;
    }
  }
  .outlet-container {
    margin-top: -10px;
    width: 100%;
    max-width: 556px;
    @include sm {
      margin-top: 0px;
    }
  }
  .settings-sub-title {
    padding: 0;
    margin: 0;
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 120%;

    &[data-lang="arabic"] {
      font-family: $Font_NotoNaskhArabicRegular;
      font-size: 30px;
      line-height: 36px;
    }
  }
  .settings-description {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    max-width: 461px;
    margin: 0;
    padding: 0;
    @include sm {
      width: 100% !important;
    }
    &[data-lang="arabic"] {
      font-family: $Font_TajawalRegular;
      font-size: 17px;
      line-height: 22.1px;
    }
  }
  .settings-description-header {
    @extend .settings-description;
    margin-top: 12px;
    margin-bottom: 33.5px;
  }
  .settings-form-control {
    width: 100%;
    @include sm {
      width: 100% !important;
    }
    &[data-lang="arabic"] {
      direction: rtl;
    }
  }
  .family-settings-warning-message {
    font-family: $Font_Montserrat_Medium;
    font-style: normal;
    font-size: 14px;
    line-height: 130%;
    max-width: 461px;
    margin-top: 28px;
    &[data-lang] {
      font-family: $Font_TajawalMedium;
    }
  }
  .notification-text {
    font-family: $Font_Montserrat_Medium;
    font-size: 15px;
    font-weight: 500;
    line-height: 140%;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;

    &[data-lang="arabic"] {
      font-family: $Font_TajawalMedium;
      font-size: 17px;
      line-height: 22.1px;
    }
  }
  .notification-description {
    font-family: $Font_Montserrat;
    font-size: 13px;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 0px;

    &[data-lang="arabic"] {
      font-family: $Font_TajawalRegular;
      font-size: 15px;
      line-height: 19.5px;
    }
  }
  .notification-snack-header {
    font-family: $Font_Montserrat_Medium;
    font-size: 14px;
    line-height: 130%;
    color: black;
    &[data-lang="arabic"] {
      font-family: $Font_TajawalMedium;
      font-size: 17px;
      line-height: 22.1px;
    }
  }
  .notification-snack-content {
    font-family: $Font_Montserrat;
    font-size: 14px;
    line-height: 18.2px;
    color: #90969e;
    &[data-lang="arabic"] {
      font-family: $Font_TajawalRegular;
      font-size: 16px;
      line-height: 19px;
    }
  }
  .child-profile {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background: #dfe1ea;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .child-profile-text {
    font-family: $Font_Montserrat_Medium;
    font-size: 14px;
    line-height: 130%;
  }
  .child-name {
    font-family: $Font_Montserrat_Medium;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    padding: 0;

    &[data-lang="arabic"] {
      font-family: $Font_TajawalMedium;
      font-size: 14px;
      line-height: 17px;
    }
  }
  .child-birth-date {
    font-family: $Font_Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #5a5f64;
    margin: 0;
    padding: 0;

    &[data-lang="arabic"] {
      font-family: $Font_TajawalRegular;
      font-size: 14px;
      line-height: 17px;
    }
  }
  .child-info-container {
    display: flex;
    padding: 12px 36px 12px 16px;
    width: 461px;
    border: 1px solid #dfe1ea;
    border-radius: 4px;
    align-items: center;
    gap: 16px;
    @include sm {
      padding-right: 20px;
      width: calc(100% - 40px) !important;
    }
  }
  .date-message-info {
    font-family: $Font_Montserrat;
    font-size: 12px;
    line-height: 16px;
    margin-top: 10px;

    &[data-lang="arabic"] {
      font-family: $Font_TajawalRegular;
      font-size: 15px;
      line-height: 19.5px;
      line-height: 130%;
      color: #5a5f64;
    }
  }

  .transaction-message {
    margin-top: 16px;
    font-family: $Font_Montserrat !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
  }
  .transaction-message-arabic {
    font-family: $Font_TajawalRegular;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
  }
}

.input-label {
  font-family: $Font_Montserrat_Medium;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 130%;
  color: #000000;
  margin: 0;
  padding: 0;
  margin-bottom: 8px;

  &[data-lang="arabic"] {
    font-family: $Font_TajawalMedium;
    font-weight: 500;
    font-size: 17px;
    line-height: 22.1px;
  }
}

.input-error-message {
  font-family: $Font_Montserrat;
  font-size: 14px;
  line-height: 18px;
  padding: 0;
  margin: 0;
  margin-top: 8px;
  color: #f66464;
  &[data-lang="arabic"] {
    font-family: $Font_TajawalRegular;
  }
}
.myCustomList {
  width: 100%;
}
.myCustomList::-webkit-scrollbar {
  width: 6px;
  background-color: #e2e4e7;
  padding: 3px;
}

.myCustomList::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #a9acaf;
}
