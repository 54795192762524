@import "./variables";
@import "./breakpoints";

.editorial-detail {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  .head {
    max-width: 440px;
    font-family: $Font_PlayfairDisplay;
    font-size: 36px;
    font-weight: 400;
    font-style: normal;
    line-height: 120%;
    color: #000000;

    &[data-lang="arabic"] {
      font-family: $Font_NotoNaskhArabicRegular;
      font-size: 38px;
      font-weight: 400;
      font-style: normal;
      line-height: 120%;

      @include sm {
        font-size: 29px;
        line-height: 43.5px;
      }
    }

    @include sm {
      font-size: 24px;
      line-height: 36px;
    }
  }
}
.pagination-editorial {
  .swiper-pagination {
    width: auto;
    right: auto;
    left: 0px;
    bottom: 12px !important;
  }

  &[data-lang="arabic"] {
    .swiper-pagination {
      width: auto;
      left: auto;
      right: 0px;
      bottom: 12px !important;
    }
  }
}
.metaDataTitleStyle {
  text-align: center;
  font-family: $Font_PlayfairDisplay;
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #ffffff;

  @include sm {
    font-size: 44px;
  }
}

.metaDataTitleArabicStyle {
  text-align: center;
  font-family: $Font_PlayfairDisplay;
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #ffffff;
}

.sponsorTitle {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  color: rgba(255, 255, 255, 0.7);
}

.sponsorTitleArabic {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  color: rgba(255, 255, 255, 0.7);
}

.shneStyle {
  font-family: $Font_ArchivoBlack;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 20px;
  white-space: break-spaces;
}

.tajawalStyle {
  font-family: $Font_TajawalBold;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 20px;
  white-space: break-spaces;
}

.buttonStyle {
  font-family: $Font_Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
}

.buttonarabicStyle {
  color: #000000;
  font-family: $Font_NotoNaskhArabicMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0em;
  text-transform: uppercase;
}

.celebrity {
  font-family: $Font_Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #000000;
  display: flex;
  align-items: center;
  margin-top: 72px;
  margin-bottom: 32px;

  @include sm {
    margin-top: 24px;
  }
}

.celebrityArabic {
  font-family: $Font_TajawalRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  color: #000000;
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 32px;
}

.text {
  font-family: $Font_Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
  white-space: break-spaces;
}

.textArabic {
  text-align: right;
  font-family: $Font_TajawalRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 130%;
  color: #000000;
  white-space: break-spaces;
}

.textStyle {
  margin-top: 16px;
  font-family: $Font_Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #000000;
}

.textStyleArabic {
  margin-top: 16px;
  font-family: $Font_TajawalRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  color: #000000;
}
