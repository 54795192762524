@font-face {
  font-family: "ArchivoBlack";
  src: url("./Fonts/ArchivoBlack-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "PlayfairDisplay";
  src: url("./Fonts/PlayfairDisplay-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "MontserratRegular";
  src: url("./Fonts/Montserrat-Regular.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "MontserratMedium";
  src: url("./Fonts/Montserrat-Medium.woff2") format("woff2");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "MontserratSemiBold";
  src: url("./Fonts/Montserrat-SemiBold.woff2") format("woff2");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "TajawalRegular";
  src: url("./Fonts/Tajawal-Regular.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "TajawalMedium";
  src: url("./Fonts/Tajawal-Medium.woff2") format("woff2");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "TajawalBold";
  src: url("./Fonts/Tajawal-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "NotoNaskhArabicRegular";
  src: url("./Fonts/NotoNaskhArabic-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "NotoNaskhArabicMedium";
  src: url("./Fonts/NotoNaskhArabic-Medium.woff2") format("woff2");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "SF Mono";
  src: url("./Fonts/SFMonoBold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

$purchasebgColor: #3a3c44;
$WPleftpageWidth: 45%;
$WPrightpageWidth: 55%;
$Font_Montserrat: "MontserratRegular";
$Font_Montserrat_Medium: "MontserratMedium";
$Font_Montserrat_Semi: "MontserratSemiBold";
$Font_PlayfairDisplay: "PlayfairDisplay";
$Font_ArchivoBlack: "ArchivoBlack";
$Font_NotoNaskhArabicRegular: "NotoNaskhArabicRegular";
$Font_NotoNaskhArabicMedium: "NotoNaskhArabicMedium";
$Font_TajawalRegular: "TajawalRegular";
$Font_TajawalMedium: "TajawalMedium";
$Font_TajawalBold: "TajawalBold";
$DefaultScreenSize: 100%;
$heightForm: calc(78%);
$heightFormTablet: calc(75.7%);
$color-1: #2ecc71;
$color-2: #3498db;
$color-3: #f1c40f;
$color-4: #e74c3c;
$primary-color: "#3498db";
:root {
  --carousalSubHeight: 35vh;
  --carousalSubHeightmobile: 400px;
  --carousalSubHeightmobileSM: 350px;
  --swiper-wrapper: 27px;
  --swiper: 20px;
  --swiper-slide: 20px;
}
