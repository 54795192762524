@import "./variables";
@import "./breakpoints";

.about {
  .menu-container {
    width: 240px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    margin-right: 80px;
    @include sm {
      width: 100%;
      margin-right: 0;
      margin-bottom: 40px;
    }
    ul {
      padding: 0;
      margin: 0;
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      gap: 26px;
    }
    .menu-item-text {
      display: flex;
      font-family: $Font_Montserrat_Medium;
      font-style: normal;
      font-size: 14px;
      cursor: pointer;
      padding: 0;
      margin: 0;

      &[data-lang="arabic"] {
        font-family: $Font_TajawalRegular;
        font-size: 18px;
        line-height: 24px;
      }
    }
    .menu-title {
      font-family: $Font_Montserrat_Semi;
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      &[data-lang="arabic"] {
        font-family: $Font_TajawalRegular;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
  .outlet-container {
    width: 100%;
    max-width: 800px;
    @include sm {
      width: initial;
      margin-top: 0px;
      max-width: initial;
    }
  }
  .content-text {
    padding: 0;
    margin: 0;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    font-family: $Font_Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;

    &[data-lang="arabic"] {
      font-family: $Font_TajawalRegular;
      font-size: 18px;
      line-height: 24px;
    }
  }
  .content-list {
    padding-left: 26px;
    margin: 0;
    margin-bottom: 24px;
  }
  .content-list-item {
    font-family: $Font_Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    padding: 2px;
    margin: 0;
    &[data-lang="arabic"] {
      font-family: $Font_TajawalRegular;
      font-size: 18px;
      line-height: 24px;
    }
  }
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  .title {
    padding: 0;
    margin: 0;
    margin-top: 40px;
    margin-bottom: 24px;
    font-family: $Font_PlayfairDisplay;
    font-size: 36px;
    font-weight: 400;
    line-height: 43px;
    @include sm {
      font-size: 28px;
      line-height: 33.6px;
    }
    &[data-lang="arabic"] {
      font-family: $Font_TajawalRegular;
      font-size: 30px;
      line-height: 36px;
    }
  }
  .table-head {
    border-bottom: 1px solid #000000;
  }
  .table-head-col {
    font-family: $Font_Montserrat_Medium;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 16px 16px 16px 0px;
  }
  .table-body-col {
    font-family: $Font_Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    padding: 16px 16px 16px 0px;
    border-bottom: 1px solid #ced0d7;
  }
}
