@import "./variables";
@import "./breakpoints";

.support-pages {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h4,
  span {
    margin: 0;
    padding: 0;
  }

  .cookie {
    max-width: 800px;
    width: 100%;
    color: white;
    font-family: $Font_Montserrat_Medium;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    .container {
      padding: 32px;
      background-color: black;
      box-shadow: 0px 0px 28px rgba(34, 38, 49, 0.35);
      border-radius: 8px;
    }
    .grid {
      display: grid;
      row-gap: 16px;
    }
    .title-container {
      font-family: $Font_TajawalMedium;
      font-weight: 500;
      font-size: 17px;
      line-height: 130%;
    }
    .title-text {
      font-family: $Font_TajawalMedium;
      font-size: 17px;
      line-height: 130%;
    }
    .description-container {
      font-weight: 400;
    }
    .description-text {
      font-weight: 400;
      &[data-lang="arabic"] {
        font-family: $Font_TajawalRegular;
        font-size: 14px;
        line-height: 17px;
      }
    }
    .manage-details-container {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include sm {
        flex-direction: column;
        align-items: initial;
      }
    }
    .action-button-container {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      @include sm {
        margin-top: 32px;
        justify-content: space-between;
      }
    }
    .manage-details-link {
      & button {
        color: white !important;
      }
    }
    .action-button {
      text-align: center;
      min-width: 120px;
      @include sm {
        width: 100%;
      }
    }
  }
  .faq {
    h1,
    h2,
    h3,
    h4,
    h5 h6,
    p {
      padding: 0;
      margin: 0;
    }
    .categories {
      .title {
        font-family: $Font_PlayfairDisplay;
        font-size: 36px;
        font-weight: 400;
        line-height: 43px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
}

.your-privacy-modal-root {
  .dialog-content-container {
    padding: 40px 48px;
    max-width: 424px;
    @include sm {
      padding: 25px 20px;
      margin-top: 20px;
    }
  }
  .title {
    margin: 0px;
    margin-bottom: 16px;
    padding: 0px;
    font-family: $Font_Montserrat_Medium;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
  }
  .title-arabic {
    margin: 0px;
    margin-bottom: 16px;
    padding: 0px;
    font-family: $Font_TajawalMedium;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
  }
  .your-privacy-description {
    margin: 0;
    padding: 0;
    font-family: $Font_Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    &[data-lang="arabic"] {
      font-family: $Font_TajawalRegular;
      font-size: 14px;
      line-height: 17px;
    }
  }
  .more-information-link-container {
    margin-top: 12px;
  }
  .more-information-link {
    margin: 0;
    padding: 0;
    font-family: $Font_Montserrat_Semi;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-decoration: underline;
    cursor: pointer;
    color: #000000;
    &[data-lang="arabic"] {
      font-family: $Font_TajawalRegular;
      font-size: 14px;
      line-height: 17px;
    }
  }
  .allow-all-button-container {
    margin-top: 16px;
    margin-bottom: 32px;
  }
  .manage-consent {
    .title {
      margin: 0;
      padding: 0;
      font-family: $Font_Montserrat_Medium;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      margin-bottom: 24px;

      &[data-lang="arabic"] {
        font-family: $Font_TajawalBold;
        font-size: 22px;
        font-weight: 600;
        line-height: 28px;
      }
    }
    .sub-content {
      margin-bottom: 24px;
    }
    .sub-title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
    }
    .sub-title {
      margin: 0;
      padding: 0;
      font-family: $Font_Montserrat_Medium;
      font-size: 14px;
      line-height: 18px;
      &[data-lang="arabic"] {
        font-family: $Font_TajawalBold;
        font-size: 16px;
        line-height: 19px;
      }
    }
    .sub-content-text {
      margin: 0;
      padding: 0;
      font-family: $Font_Montserrat;
      font-size: 12px;
      line-height: 16px;
      &[data-lang="arabic"] {
        font-family: $Font_TajawalRegular;
        font-size: 14px;
        line-height: 17px;
      }
    }
    .always-active {
      margin: 0;
      padding: 0;
      font-family: $Font_Montserrat_Medium;
      font-size: 14px;
      line-height: 18.2px;
      color: #4dc862;
      &[data-lang="arabic"] {
        font-family: $Font_TajawalRegular;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}
