@import "./variables";
@import "./breakpoints";
.alliance {
  display: flex;
  height: 100%;
  justify-content: space-between;
  &[data-lang="arabic"] {
    flex-direction: row-reverse;
  }
  [role="presentation"] {
    background-color: #505050;
    @include md {
      background-color: #f5f5f5;
    }
  }
  .amber-alliance-form-text {
    font-family: $Font_Montserrat_Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #000000;
    width: 100%;
    margin-bottom: 8px;
  }
  .button-submit-loading {
    font-family: $Font_Montserrat;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    font-size: 14px;
    height: 42px;
    width: 288px;
    margin-top: 0px;
    background: rgba(90, 95, 100, 0.5);
    border-radius: 34px;
    color: #ffffff;
    cursor: pointer;
    @include sm {
      width: 100%;
      max-width: 100%;
    }
  }
  .amber-alliance-form-text-arabic {
    font-family: $Font_TajawalMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #000000;
    width: 100%;
    margin-bottom: 8px;
  }
  label {
    top: 0px !important;
  }
  .MuiOutlinedInput-root {
    padding-right: 5px !important;
  }

  .MuiFormHelperText-root {
    color: #f66464 !important;
    margin-left: 0px !important;
    font-family: $Font_Montserrat;
    font-size: 14px !important;
    line-height: 130% !important;
    margin-top: 8px !important;
  }

  .phone-text {
    font-family: $Font_Montserrat_Medium;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 130%;
    color: #000000;
  }
  .phone-text-arabic {
    font-family: $Font_TajawalMedium;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 130%;
    color: #000000;
  }
  .phone-text-pd {
    @extend .phone-text;
    margin-bottom: 8px;
  }
  .phone-text-pd-arabic {
    @extend .phone-text-arabic;
    margin-bottom: 8px;
  }
  .datetext {
    font-family: $Font_Montserrat !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5a5f64;
  }

  .datetext-arabic {
    font-family: $Font_TajawalRegular !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5a5f64;
  }
  .datetext-sub {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    margin-top: 15px;
    color: #5a5f64;
    display: flex;
    align-items: center;
    height: 100%;

    span {
      padding: 0px;
      margin-right: 12px;
    }
  }
  .datetext-sub-arabic {
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    margin-top: 15px;
    color: #5a5f64;
    display: flex;
    align-items: center;
    height: 100%;
    span {
      padding: 0px;
      margin-left: 12px;
    }
  }
  .WPrightpage {
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    // background-image: $backgroundRightImage;
    height: 100%;
    width: $WPrightpageWidth;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: fixed;
    @include xl {
      display: none;
    }
  }
  .remember-signin {
    align-items: center;
    align-content: center;
    margin-top: 20px !important;
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    height: 20px;
  }
  .remember-signin-arabic {
    align-items: center;
    align-content: center;
    margin-top: 20px !important;
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    height: 20px;
  }
  .WPrighttext {
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    line-height: 120%;
    color: #ffffff;
    width: 100%;
    max-width: 540px;
  }
  .WPleftpage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    height: 100%;
    width: $WPleftpageWidth;
    position: relative;
    left: 0;
    right: auto;
    &[data-lang="arabic"] {
      left: auto;
      right: 0;
    }
    @include xl {
      width: 100%;
    }
    @include smheight2 {
      min-height: 180vh;
      height: 100%;
    }
  }
  .left-page-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
  }
  .left-page-form {
    max-width: 460px;
    width: calc(100% - 40px);
    height: 100%;
    padding-top: 142px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    padding-bottom: 53px;
    @include md {
      padding-top: 48px;
      padding-bottom: 49px;
      height: calc(100% - 97px);
    }
    .login-head {
      font-style: normal;
      font-weight: 400;
      font-size: 44px;
      line-height: 120%;
      color: #000000;
      font-family: $Font_PlayfairDisplay;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;
      @include sm {
        font-size: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
      }
      @include xs {
        font-size: 24px;
      }
    }
    .login-head-arabic {
      width: 460px;
      height: 43px;
      left: 444px;
      top: 192px;
      font-family: $Font_NotoNaskhArabicRegular;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include md {
        width: 100%;
        height: 38px;
        font-size: 28px;
      }
    }
  }
  .sub-text {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    margin-top: 16px;
    justify-content: center;
  }
  .sub-text-arabic {
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    margin-top: 16px;
    justify-content: center;
  }
  .Language-select {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 105px;
    display: flex;
    z-index: 5;
    justify-content: space-between;
    @include md {
      right: 20px;
      top: 24px;
    }
    &[data-lang="arabic"] {
      right: auto;
      left: 32px;
      flex-direction: row-reverse;
      @include md {
        right: auto;
        left: 20px;
        top: 24px;
      }
    }
  }

  .verify-text {
    max-width: 460px;
    width: 100%;
    font-family: $Font_Montserrat_Semi;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #000000;
  }
  .verify-text-arabic {
    max-width: 460px;
    width: 100%;
    font-family: $Font_TajawalBold;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #000000;
  }
  .subtext-box {
    max-width: 460px;
    width: 100%;
    min-height: 54px;
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    display: flex;
    align-items: flex-start;
    color: #000000;
    justify-content: space-between;
    flex-direction: column;
    margin: 12px 0px 40px 0px;
    @include md {
      margin: 12px 0px 32px 0px;
    }
  }
  .subtext-box-arabic {
    max-width: 460px;
    width: 100%;
    min-height: 54px;
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    display: flex;
    align-items: flex-end;
    color: #000000;
    justify-content: space-between;
    flex-direction: column;
    margin: 12px 0px 40px 0px;
    @include md {
      margin: 12px 0px 32px 0px;
    }
  }
  .marginbox {
    margin: 12px 0px 24px 0px !important;
    @include md {
      margin: 12px 0px 16px 0px !important;
    }
  }
  .otp-time-text {
    margin-top: 8px;
    font-family: $Font_Montserrat !important;
    font-size: 14px;
    color: #000000;
  }
  .otp-time-text-arabic {
    margin-top: 8px;
    font-family: $Font_TajawalRegular !important;
    font-size: 14px;
    color: #000000;
  }

  .login-head {
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 120%;
    color: #000000;
    font-family: $Font_PlayfairDisplay;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    @include sm {
      font-size: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000;
    }
    @include xs {
      font-size: 24px;
    }
  }
  .login-head-arabic {
    width: 460px;
    height: 43px;
    left: 444px;
    top: 192px;
    font-family: $Font_NotoNaskhArabicRegular;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include md {
      width: 100%;
      height: 38px;
      font-size: 28px;
    }
  }
  .barcode-text {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
  }
  .barcode-text-arabic {
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
  }
}

.lng-not-selected {
  font-family: $Font_Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #5a5f64;
  background: transparent;
  border: none;
  cursor: pointer;
}
.lng-selected {
  font-family: $Font_Montserrat_Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: #000000;
  background: transparent;
  border: none;
  cursor: pointer;
}
.search-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 460px;
  width: 100%;
  height: auto;
  border: 1px solid #dfe1ea;
  border-radius: 8px;
  margin-top: 24px;
  overflow: auto;
  justify-content: center;
  box-sizing: content-box;
}
.parthner-name {
  font-family: $Font_Montserrat_Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  justify-content: center;
  margin-top: 16px;
}
.parthner-name-arabic {
  font-family: $Font_TajawalMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  justify-content: center;
  margin-top: 16px;
}
.barcode-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0px;
  width: 100%;
  letter-spacing: 0.5em;
  text-transform: uppercase;
}
.amber-alliance-alert-text {
  color: var(--primary-black, #000);
  text-align: center;
  font-size: 14px;
  font-family: $Font_Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-block-start: 16px;
}
.amber-alliance-alert-text-arabic {
  color: var(--primary-black, #000);
  text-align: center;
  font-size: 14px;
  font-family: $Font_TajawalRegular;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-block-start: 16px;
}
