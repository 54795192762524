@import "./variables";
@import "./breakpoints";

.footer-main {
  background-color: black;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  .box-select {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  .selectbox-country {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 14px;
    max-width: initial;
    height: 16px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    text-transform: none;
    width: 100%;
    @include xl {
      padding: 8px;
      width: auto;
      height: 16px;
    }
  }
  .selectbox-country-text {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    text-align: left;
    direction: ltr;
  }
  .selectbox-country-text-arabic {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #ffffff;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    text-align: left;
    direction: rtl;
  }
  .select-box-right-text {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    max-width: 194px;
    width: 100%;
    color: #ffffff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    margin-top: 28px;

    @include xl {
      justify-content: center;
      margin-top: 48px;
    }
    @include md {
      margin-right: 0px;
    }
  }
  .select-box-right-text-arabic {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    max-width: 194px;
    width: 100%;
    color: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: right;
    margin-top: 28px;

    @include xl {
      justify-content: center;
      margin-top: 48px;
    }
    @include md {
      margin-right: 0px;
    }
  }
  .social-box {
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }
  .selectbox-inline-text {
    text-align: left;
    padding-right: 10px;
    min-width: 185px;
    padding-left: 10px;
  }
  .selectbox-inline-text-arabic {
    text-align: right;
    padding-right: 15px;
    padding-left: 10px;
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 130%;
    min-width: 185px;
  }
  .selectbox-country-image {
    height: 20px;
    width: 20px;
    border-radius: 50%;
  }
  .right-text-span {
    font-family: $Font_Montserrat;
    font-size: 12px;
    line-height: 130%;
    background: transparent;
    color: white;
    cursor: pointer;
    border: none;
    margin: 0;
    padding: 0;
  }
  .footer-language-menu {
    width: 97px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
  .footer-language-menu-arabic {
    width: 97px;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .right-text-divider {
    color: #5a5f64;
  }
  .footer-link {
    width: 322px;
    height: 21px;
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #90969e;
    margin-top: 48px;
    cursor: pointer;
  }
}

.footer-select-country {
  width: 100%;
  height: 100%;
  max-width: 222px;
  padding: 20px;
  position: relative;

  .select-country-head {
    color: #000000;
    min-height: 16px;
    font-family: $Font_Montserrat_Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
  }
  .select-country-head-arabic {
    font-family: $Font_TajawalBold;
    min-height: 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    color: #000000;
    text-align: right;
  }
  .select-country-text {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-size: 12px;
    line-height: 130%;
    color: #000000;
    margin-top: 8px;
    font-weight: 400;
  }
  .select-country-text-arabic {
    @extend .select-country-head-arabic;
    font-family: $Font_TajawalRegular;
    font-weight: 400;
    margin-top: 8px;
    direction: rtl;
  }
  .custom-radios {
    display: flex !important;
    flex-direction: column;
    margin-top: 40px;

    div {
      display: inline-block;
    }

    .SC-radio-box {
      font-family: $Font_Montserrat_Medium;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      font-size: 12px;
      line-height: 130%;
      color: #000000;
    }
    .SC-radio-box-arabic {
      font-family: $Font_TajawalRegular;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 130%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 18px;
      flex-direction: row-reverse;
      color: #000000;
    }

    .SC-box-1 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .SC-box-2 {
      display: flex;
      justify-content: flex-end;
    }
    .SC-box-1-arabic {
      display: flex;
      justify-content: flex-start;
      flex-direction: row-reverse;
      align-items: center;
    }

    .SC-box-2-arabic {
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
    }

    input[type="radio"] {
      display: none;
      + label {
        color: #333;
        font-family: Arial, sans-serif;
        font-size: 14px;
        span {
          width: 20px;
          height: 20px;
          margin: 0;
          vertical-align: middle;
          cursor: pointer;
          border-radius: 50%;
          border: 1px solid #dfe1ea;
          background-repeat: no-repeat;
          background-position: center;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            transition: none;
            width: 10px;
          }
        }
      }

      &#color-1 + label span {
        background-color: #f3f3fa;
      }

      &#color-2 + label span {
        background-color: #f3f3fa;
      }

      &#color-3 + label span {
        background-color: #f3f3fa;
      }

      &#color-4 + label span {
        background-color: #f3f3fa;
      }

      &#color-5 + label span {
        background-color: #f3f3fa;
      }
      &#color-6 + label span {
        background-color: #f3f3fa;
      }
    }
  }

  &__loading {
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
}

.headText {
  font-family: $Font_ArchivoBlack;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #90969e;
  margin-bottom: 24px;
  text-align: left;
  height: 100%;
}
.headTextArabic {
  font-family: $Font_TajawalBold;
  font-size: 14px;
  line-height: 110%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 700;
  text-align: right;
  color: #90969e;
  height: 100%;
}
