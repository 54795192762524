@import "./variables";
@import "./breakpoints";
.amber-app {
  .events-offers-swiper {
    .swiper {
      .swiper-wrapper {
        margin: 48px 0 0 0;
      }
    }
  }

  .swiper {
    height: 100%;
    min-height: 100%;

    .swiper-wrapper {
      margin-top: 0px;
      display: flex;
      justify-content: space-between;
    }
    .swiper-pagination {
      position: relative;
      bottom: 0px;
      margin-top: 52px;
      direction: ltr;
    }
    & [data-lang="arabic"] {
      .swiper-pagination {
        direction: rtl;
      }
    }
    .swiper-pagination-bullet {
      border-radius: 0%;
      width: 56px;
      height: 2px;
    }
    .swiper-pagination-bullet-active {
      background: #000000;
    }
    .my-bullet-class {
      background: #5a5f64;
    }
    .my-bullet-active-class {
      background: white;
    }
    .swiper-button-prev {
      right: 36px !important;
      left: auto !important;
      top: 10px !important;
      color: #000000;
      @include xxl {
        top: 10px !important;
      }
      @include xl {
        top: 10px !important;
      }
      @include md {
        top: 10px !important;
      }
      @include sm {
        top: 10px !important;
      }
    }

    .swiper-button-next {
      right: 0px !important;
      left: auto !important;
      top: 10px !important;
      color: #000000;
      @include xxl {
        top: 10px !important;
      }
      @include xl {
        top: 10px !important;
      }
      @include md {
        top: 10px !important;
      }
      @include sm {
        top: 10px !important;
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      width: 7px;
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
      font-size: 14px;
      text-transform: none !important;
      letter-spacing: 0;
      font-variant: initial;
      line-height: 1;
    }
    .section-text-head {
      height: 100%;
      font-family: $Font_ArchivoBlack;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #000000;
      text-align: center;
      margin-bottom: 20px;
    }
    .section-text-head-arabic {
      height: 18px;
      font-family: $Font_TajawalMedium;
      font-size: 17px;
      line-height: 18px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #000000;
      text-align: center;
      margin-bottom: 20px;
    }
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      top: 20px;
    }

    .swiper-text-brands {
      height: 12px;
      font-family: $Font_ArchivoBlack;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #000000;
    }
  }
  &.amberHeadContainer {
    z-index: 9;
    position: relative;
    width: -webkit-fill-available;
  }
  .as-head {
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 120%;
    color: #000000;
    text-align: left;
    &.amberHeadTitle {
      margin: 0px 0 -26px 0;
      min-width: min-content;
    }
  }
  .as-head-arabic {
    font-family: $Font_NotoNaskhArabicRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    width: 100%;
    line-height: 120%;
    color: #000000;
    @include md {
      font-size: 30px;
      height: 100%;
    }
    text-align: right;
    &.amberHeadTitle {
      margin: 0px 0 -26px 0;
      min-width: min-content;
    }
  }
  .as-text {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #5a5f64;
    text-align: left;
  }
  .as-text-arabic {
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 130%;
    text-align: right;
    color: #5a5f64;
    direction: rtl;
  }
}
.amber-info-card-head {
  font-family: $Font_PlayfairDisplay;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 120%;
  color: #000000;
  margin-bottom: 22px;
  text-align: left;
  @include md {
    margin-bottom: 4px;
    font-size: 28px;
  }
}
.amber-info-card-head-arabic {
  font-family: $Font_NotoNaskhArabicRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 120%;
  color: #000000;
  margin-bottom: 22px;
  text-align: right;
  @include md {
    margin-bottom: 4px;
    font-size: 30px;
  }
}
.button-change {
  width: 160px !important;
}
.amber-app-category-card-subtext {
  font-family: $Font_Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #5a5f64;
  margin-top: 8px;
  min-height: 36px;
  @include md {
    height: 100%;
  }
}
.amber-app-category-card-subtext-arabic {
  font-family: $Font_TajawalRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;
  text-align: right;
  color: #5a5f64;
  margin-top: 8px;
  min-height: 40px;
  @include md {
    height: 100%;
  }
}
