@import "./variables";
@import "./breakpoints";
.error {
  display: flex;
  justify-content: center;
  padding-top: 280px;
  padding-bottom: 200px;
  height: 100%;
  @include md {
    padding-top: 208px;
    padding-bottom: 160px;
  }
  .error-main-component {
    max-width: 1120px;
    width: 100%;
    max-height: 273px;
    height: auto;
    @include md {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .error-main-text {
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    font-size: 120px;
    line-height: 120%;
    text-align: center;
    color: #000000;
    @include md {
      font-size: 72px;
    }
  }
  .error-sup-text {
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 120%;
    text-align: center;
    color: #000000;
    @include md {
      font-size: 28px;
    }
  }
  .error-sup-text-arabic {
    font-family: $Font_NotoNaskhArabicRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 120%;
    text-align: center;
    color: #000000;
    @include md {
      font-size: 28px;
    }
  }
  .button-box-error {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    max-height: 36px;
    height: auto;
  }
  .error-button {
    font-family: $Font_Montserrat_Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    color: #000000;
    padding: 10px 16px;
    background: #f3f3fa;
    border-radius: 34px;
    border: none;
  }
  .error-button-arabic {
    font-family: $Font_TajawalMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    color: #000000;
    padding: 10px 16px;
    background: #f3f3fa;
    border-radius: 34px;
    border: none;
  }
  .small-text {
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #000000;
    margin-top: 24px;
  }
  .small-text-arabic {
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #000000;
    margin-top: 24px;
  }
}
