$breakpoints: (
  "xs": 0,
  "sm": 600px,
  "md": 900px,
  "xl": 1200px,
  "xxl": 1500px,
  "_3xl": 1700px
);
@mixin _3xl {
  @media (max-width: map-get($breakpoints,"_3xl")) {
    @content;
  }
}
@mixin xxl {
  @media (max-width: map-get($breakpoints,"xxl")) {
    @content;
  }
}
@mixin xl {
  @media (max-width: map-get($breakpoints,"xl")) {
    @content;
  }
}
@mixin lg {
  @media (max-width: map-get($breakpoints,"lg")) {
    @content;
  }
}
@mixin md {
  @media (max-width: map-get($breakpoints,"md")) {
    @content;
  }
}
@mixin sm {
  @media (max-width: map-get($breakpoints,"sm")) {
    @content;
  }
}
@mixin xs {
  @media (max-width: map-get($breakpoints,"xs")) {
    @content;
  }
}
@mixin smheight {
  @media (max-height: map-get($breakpoints,"md")) {
    @content;
  }
}
@mixin smheight2 {
  @media (max-height: map-get($breakpoints,"sm")) {
    @content;
  }
}
@mixin between($min, $max) {
  @media (min-width: map-get($breakpoints, $min)) and (max-width: map-get($breakpoints,$max)) {
    @content;
  }
}
