@import "./variables";
@import "./breakpoints";

.Nav {
  text-transform: none !important;
  height: 80px !important;
  @include md {
    height: 48px !important;
  }

  .Navmenu {
    background-color: transparent;
    font-family: $Font_Montserrat !important;
    box-shadow: none;
  }
  .nav-list-a {
    text-decoration: none;
    color: #000000;
    font-family: $Font_Montserrat;
  }
  .Navmenu-light {
    background-color: white;
    font-family: $Font_Montserrat !important;
    box-shadow: none;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  }
  .Navmenu-light-other {
    background-color: transparent;
    font-family: $Font_Montserrat !important;
    box-shadow: none;
  }
  .Navmenu-light-other2 {
    background-color: white;
    font-family: $Font_Montserrat !important;
    box-shadow: none;
  }
  .Navmenu-light-other-scroll {
    background-color: transparent;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  }
  .NavButton {
    font-weight: 400;
    line-height: 130%;
    color: #ffffff;
    text-transform: none;
  }
  .NavButton-arabic {
    font-size: 17px;
    text-transform: none;
    font-weight: 400;
    line-height: 130%;
    color: #ffffff;
  }
  .NavButton-light {
    text-transform: none;
    font-weight: 400;
    line-height: 130%;
    color: #000000;
  }
  .NavButton-crate-account {
    padding: 0px 20px;
    min-width: 149px;
    height: 30px;
    background-color: #ffffff;
    border-radius: 32px;
    color: black;
    text-transform: none !important;
  }
  .NavButton-crate-account-light {
    padding: 0px 20px;
    min-width: 149px;
    height: 30px;
    background-color: #000000;
    border-radius: 32px;
    color: #ffffff;
    text-transform: none !important;
  }

  .button-crate-account {
    text-transform: none !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 12px 20px !important;
    width: 460px !important;
    height: 42px !important;
    background-color: #000000;
    border-radius: 34px !important;
    color: #ffffff;
    cursor: pointer !important;

    @include sm {
      width: 100% !important;
      max-width: 350px !important;
    }

    @include xs {
      width: 100% !important;
      max-width: 265px !important;
    }
  }

  .button-signin {
    text-transform: none !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 12px 20px !important;
    width: 460px !important;
    height: 42px !important;
    background-color: white;
    border-radius: 34px !important;
    color: #000000;
    box-shadow: none;
    cursor: pointer !important;
    margin: 16px 5px 5px 5px;

    @include sm {
      width: 100% !important;
      max-width: 350px !important;
    }

    @include xs {
      width: 100% !important;
      max-width: 265px !important;
    }
  }

  .create-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 50.5% !important;
    flex-direction: column;
    @include sm {
      justify-content: center;
      position: absolute;
      top: 78.5% !important;
    }
  }
}

.Carousel-main {
  .carousel-image {
    width: 100% !important;
    height: 100% !important;
  }

  .carousel-head {
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: #ffffff;
    width: 100%;
  }

  .carousel-subtext {
    margin-top: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    width: 100%;
    max-width: 547px;
    color: rgba(255, 255, 255, 0.7);
    @include md {
      font-size: 16px;
    }
    @include sm {
      max-width: 342px;
    }
  }

  .carousel-subtext-tiny {
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    color: #ffffff;
    margin-top: 32px;
    @include xl {
      display: none !important;
    }
  }

  .carousel-subtext-tiny-product {
    @extend .carousel-subtext-tiny;
    margin-top: 8px;
    color: #5a5f64;
  }

  .carousel-qr {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    position: relative;
    z-index: 1000;
    @include xl {
      margin-top: 40px;
    }
  }
}

.events-offers-swiper {
  .swiper {
    .swiper-wrapper {
      margin: 48px 0 0 0;
    }
  }
}

.swiper {
  height: 100%;
  min-height: 100%;

  .swiper-wrapper {
    margin-top: 24px;
  }
  .swiper-pagination {
    position: absolute;
    bottom: 0px;
    margin-top: 30px;
    @include md {
      bottom: 2px;
      margin-top: 8px;
    }
  }
  .swiper-pagination-bullet {
    border-radius: 0%;
    width: 56px;
    height: 2px;
  }
  .swiper-pagination-bullet-active {
    background: #000000;
  }
  .my-bullet-class {
    background: #5a5f64;
  }
  .my-bullet-active-class {
    background: white;
  }
  .swiper-button-prev {
    right: 36px;
    left: auto;
    top: 10px;
    color: #000000;
    @include xxl {
      top: 10px;
    }
    @include xl {
      top: 10px;
    }
    @include md {
      top: 10px;
    }
    @include sm {
      top: 10px !important;
    }
  }

  .swiper-button-next {
    right: 0px;
    left: auto;
    top: 10px;
    color: #000000;
    @include xxl {
      top: 10px;
    }
    @include xl {
      top: 10px;
    }
    @include md {
      top: 10px;
    }
    @include sm {
      top: 10px;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 7px;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 14px;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
  }
  .section-text-head {
    height: 18px;
    font-family: $Font_ArchivoBlack;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #000000;
    text-align: center;
    margin-bottom: 8px;
    @include sm {
      margin-bottom: 16px;
    }
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    top: 18px;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-text-brands {
    height: 12px;
    font-family: $Font_ArchivoBlack;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #000000;
    text-align: left;
  }
  .swiper-text-brands-arabic {
    height: 17px;
    font-family: $Font_TajawalBold;
    font-size: 17px;
    line-height: 12px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;
    text-align: right;
  }
}
.carousal-swiper {
  .show-box {
    display: flex;
  }
  .hide-box {
    display: none;
  }
  .swiper {
    height: 100%;
    min-height: 100%;
    @include sm {
      min-height: 335px;
      height: 100%;
    }
    .swiper-wrapper {
      margin-top: 0px;
    }
    .swiper-pagination {
      bottom: 61px;
      margin-top: 0px;
      @include md {
        bottom: 37px;
      }
    }
    .swiper-pagination-bullet {
      border-radius: 0%;
      width: 56px;
      height: 2px;
      background: #5a5f64;
      opacity: 1;
      cursor: pointer;
    }
    .swiper-pagination-bullet-active {
      background: #ffffff;
    }
    .swiper-button-prev {
      right: 36px;
      left: auto;
      top: 10px;
      color: #000000;
      @include xxl {
        top: 10px;
      }
      @include xl {
        top: 10px;
      }
      @include md {
        top: 10px;
      }
      @include sm {
        top: 10px;
      }
    }

    .swiper-button-next {
      right: 20px;
      left: auto;
      top: 10px;
      color: #000000;
      @include xxl {
        top: 10px;
      }
      @include xl {
        top: 10px;
      }
      @include md {
        top: 10px;
      }
      @include sm {
        top: 10px;
      }
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
      font-size: 14px;
      text-transform: none !important;
      letter-spacing: 0;
      font-variant: initial;
      line-height: 1;
    }
    .section-text-head {
      height: 18px;
      font-family: $Font_ArchivoBlack;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #000000;
      text-align: center;
      margin-bottom: 8px;
      @include sm {
        margin-bottom: 16px;
      }
    }
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      width: fit-content;
      top: 0px;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .swiper-text-brands {
      height: 12px;
      font-family: $Font_ArchivoBlack;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #000000;
    }
  }
}
.create-content {
  .create-content-right-section {
    height: 720px;
    display: flex;
    align-items: center;

    @include xxl {
      height: 570px;
    }
    @include lg {
      height: 530px;
    }
    @include sm {
      height: 450px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @include xs {
      height: 450px;
    }
  }
  .create-content-right-section-arabic {
    height: 720px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include xxl {
      height: 570px;
    }
    @include lg {
      height: 530px;
    }
    @include sm {
      height: 450px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @include xs {
      height: 450px;
    }
  }
  .create-content-left-section {
    height: 100%;
    margin-bottom: 30px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @include xxl {
      margin-bottom: 30px;
    }
    @include sm {
      height: 100%;
      margin-bottom: 32px;
    }
  }
  .create-content-left-section-arabic {
    height: 100%;
    margin-bottom: 30px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    @include xxl {
      margin-bottom: 30px;
    }
    @include sm {
      height: 100%;
      margin-bottom: 32px;
    }
  }
  .create-content-left-section2 {
    height: 720px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    @include xxl {
      height: 570px;
      margin-bottom: 30px;
    }

    @include sm {
      height: 100%;
      margin-bottom: 32px;
    }
  }
  .create-content-left-section2-arabic {
    height: 720px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include xxl {
      height: 570px;
      margin-bottom: 30px;
    }

    @include sm {
      height: 100%;
      margin-bottom: 32px;
    }
  }
  .cc-div {
    max-width: 820px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    width: 100%;
    height: 100%;
    @include xxl {
      height: 100%;
      max-width: 520px;
    }
  }
  .cc-div-arabic {
    max-width: 820px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;

    @include xxl {
      height: 100%;
      max-width: 520px;
    }
  }
  .cc-div2 {
    max-width: 820px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    width: 100%;
    height: 100%;
    @include xxl {
      height: 100%;
      max-width: 520px;
    }
  }
  .cc-div2-arabic {
    max-width: 820px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    @include xxl {
      height: 100%;
      max-width: 520px;
    }
  }
  .dynamic-ads {
    font-family: $Font_ArchivoBlack;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #000000;
  }
  .dynamic-ads-arabic {
    font-family: $Font_TajawalBold;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 110%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;
    direction: rtl;
  }
  .dynamic-ads-featured-item {
    font-family: $Font_ArchivoBlack;
    text-transform: uppercase;
    color: #000000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 116.667% */
    letter-spacing: 2.4px;
    margin: 16px 0px;
    @include md {
      margin: 16px 0px 8px 0px;
    }
  }
  .dynamic-ads-featured-item-arabic {
    font-family: $Font_TajawalBold;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 110%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;
    margin: 16px 0px;
    @include md {
      margin: 16px 0px 8px 0px;
    }
  }
  .left-section-head {
    max-width: 520px;
    width: 100%;
    margin-top: 16px;
    height: 100%;
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    font-size: 54px;
    line-height: 120%;
    color: #000000;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @include xl {
      font-size: 44px;
    }
    @include lg {
      max-width: 100%;
    }
    @include sm {
      font-size: 44px;
    }
  }
  .left-section-head-featured-items {
    max-width: 520px;
    width: 100%;
    margin-top: 24px;
    height: 100%;
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 120%;
    color: #000000;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @include lg {
      max-width: 100%;
    }
    @include md {
      margin-top: 20px;
      font-size: 28px;
    }
  }
  .left-section-head-arabic {
    max-width: 520px;
    width: 100%;
    height: 100%;
    font-family: $Font_NotoNaskhArabicRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 130%;
    text-align: right;
    color: #000000;
    margin-top: 16px;
    @include lg {
      max-width: 100%;
    }
  }
  .left-section-head-featured-items-arabic {
    max-width: 520px;
    width: 100%;
    height: 100%;
    font-family: $Font_NotoNaskhArabicRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 130%;
    text-align: right;
    color: #000000;
    margin-top: 24px;
    direction: rtl;
    @include lg {
      max-width: 100%;
    }
    @include md {
      margin-top: 20px;
      font-size: 28px;
    }
  }
  .left-section-text {
    height: 100%;
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #5a5f64;
    margin-top: 32px;
    width: 100%;
    max-width: 520px;
    @include lg {
      max-width: 100%;
    }
    @include md {
      margin-top: 24px;
    }
  }
  .left-section-text-arabic {
    height: 100%;
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 130%;
    text-align: right;
    color: #5a5f64;
    max-width: 520px;
    width: 100%;
    margin-top: 32px;
    @include lg {
      max-width: 100%;
    }
    @include md {
      margin-top: 24px;
    }
  }
  .left-section-text-featured-items {
    height: 100%;
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #5a5f64;
    width: 100%;
    max-width: 520px;
    @include lg {
      max-width: 100%;
      font-size: 12px;
    }
  }
  .left-section-text-featured-items-arabic {
    height: 100%;
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 130%;
    text-align: right;
    color: #5a5f64;
    max-width: 520px;
    width: 100%;
    direction: rtl;
    @include lg {
      max-width: 100%;
    }
    @include md {
      margin-top: 24px;
    }
  }
  .how-it-works-text {
    width: 100%;
    height: 100%;
    max-width: 1120px;
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #5a5f64;
    @include md {
      font-size: 14px;
      line-height: 130%;
    }
  }
  .how-it-works-text-arabic {
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 130%;
    text-align: right;
    color: #5a5f64;
  }
  .button-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .button-menu-arabic {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .button-submit-arabic {
    font-family: $Font_TajawalMedium !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    border-radius: 34px !important;
    color: #ffffff;
    font-size: 17px;
    cursor: pointer;
    gap: 12px;
    width: 200px;
    height: 42px;
    @include sm {
      max-width: 350px;
    }
  }
  .button-submit {
    font-family: $Font_Montserrat_Medium !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    border-radius: 34px !important;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    gap: 12px;
    width: 200px;
    height: 42px;
    @include sm {
      max-width: 350px;
    }
  }
}
.create-content-featured-Item {
  .create-content-right-section {
    height: 720px;
    display: flex;
    align-items: center;

    @include xxl {
      height: 570px;
    }
    @include lg {
      height: 530px;
    }
    @include sm {
      height: 450px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @include xs {
      height: 450px;
    }
  }
  .create-content-right-section-arabic {
    height: 720px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include xxl {
      height: 570px;
    }
    @include lg {
      height: 530px;
    }
    @include sm {
      height: 450px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @include xs {
      height: 450px;
    }
  }
  .create-content-left-section {
    height: 100%;
    margin-bottom: 30px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @include xxl {
      margin-bottom: 30px;
    }
    @include sm {
      height: 100%;
      margin-bottom: 32px;
    }
  }
  .create-content-left-section-arabic {
    height: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    @include xxl {
      margin-bottom: 30px;
    }
    @include sm {
      height: 100%;
      margin-bottom: 32px;
    }
  }
  .create-content-left-section2 {
    height: 720px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    @include xxl {
      height: 570px;
      margin-bottom: 30px;
    }

    @include sm {
      height: 100%;
      margin-bottom: 32px;
    }
  }
  .create-content-left-section2-arabic {
    height: 720px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include xxl {
      height: 570px;
      margin-bottom: 30px;
    }

    @include sm {
      height: 100%;
      margin-bottom: 32px;
    }
  }
  .cc-div {
    max-width: 820px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    width: 100%;
    height: 100%;
    @include xxl {
      height: 100%;
      max-width: 520px;
    }
  }
  .cc-div-arabic {
    max-width: 820px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;

    @include xxl {
      height: 100%;
      max-width: 520px;
    }
  }
  .cc-div2 {
    max-width: 820px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    width: 100%;
    height: 100%;
    @include xxl {
      height: 100%;
      max-width: 520px;
    }
  }
  .cc-div2-arabic {
    max-width: 820px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    @include xxl {
      height: 100%;
      max-width: 520px;
    }
  }
  .dynamic-ads {
    font-family: $Font_ArchivoBlack;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #000000;
  }
  .dynamic-ads-arabic {
    font-family: $Font_TajawalBold;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 110%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;
  }
  .dynamic-ads-featured-item {
    font-family: $Font_ArchivoBlack;
    text-transform: uppercase;
    color: #000000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 116.667% */
    letter-spacing: 2.4px;
    margin: 16px 0px;
    @include md {
      margin: 16px 0px 0px 0px;
    }
  }
  .dynamic-ads-featured-item-arabic {
    font-family: $Font_TajawalBold;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 110%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;
    margin: 16px 0px;
    @include md {
      margin: 16px 0px 0px 0px;
    }
  }
  .left-section-head {
    max-width: 520px;
    width: 100%;
    margin-top: 16px;
    height: 100%;
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    font-size: 54px;
    line-height: 120%;
    color: #000000;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @include xl {
      font-size: 44px;
    }
    @include lg {
      max-width: 100%;
    }
    @include sm {
      font-size: 44px;
    }
  }
  .left-section-head-featured-items {
    max-width: 520px;
    width: 100%;
    margin-top: 24px;
    height: 100%;
    font-family: $Font_PlayfairDisplay;
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 120%;
    color: #000000;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @include lg {
      max-width: 100%;
    }
    @include md {
      margin-top: 20px;
      font-size: 28px;
    }
  }
  .left-section-head-arabic {
    max-width: 520px;
    width: 100%;
    height: 100%;
    font-family: $Font_NotoNaskhArabicRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 130%;
    text-align: right;
    color: #000000;
    margin-top: 16px;
    @include lg {
      max-width: 100%;
    }
  }
  .left-section-head-featured-items-arabic {
    max-width: 520px;
    width: 100%;
    height: 100%;
    font-family: $Font_NotoNaskhArabicRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 130%;
    text-align: right;
    color: #000000;
    margin-top: 24px;
    @include lg {
      max-width: 100%;
    }
    @include md {
      margin-top: 20px;
      font-size: 28px;
    }
  }
  .left-section-text {
    height: 100%;
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #5a5f64;
    margin-top: 32px;
    width: 100%;
    max-width: 520px;
    @include lg {
      max-width: 100%;
    }
    @include md {
      margin-top: 24px;
    }
  }
  .left-section-text-arabic {
    height: 100%;
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 130%;
    text-align: right;
    color: #5a5f64;
    max-width: 520px;
    width: 100%;
    margin-top: 32px;
    @include lg {
      max-width: 100%;
    }
    @include md {
      margin-top: 24px;
    }
  }
  .left-section-text-featured-items {
    height: 100%;
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #5a5f64;
    width: 100%;
    max-width: 520px;
    margin-top: 8px;
    @include lg {
      max-width: 100%;
      font-size: 12px;
    }
  }
  .left-section-text-featured-items-arabic {
    height: 100%;
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 130%;
    text-align: right;
    color: #5a5f64;
    max-width: 520px;
    width: 100%;
    margin-top: 8px;
    @include lg {
      max-width: 100%;
    }
  }
  .how-it-works-text {
    width: 100%;
    height: 100%;
    max-width: 1120px;
    font-family: $Font_Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #5a5f64;
    @include md {
      font-size: 14px;
      line-height: 130%;
    }
  }
  .how-it-works-text-arabic {
    font-family: $Font_TajawalRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 130%;
    text-align: right;
    color: #5a5f64;
  }
  .button-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .button-menu-arabic {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .button-submit-arabic {
    font-family: $Font_TajawalMedium !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    border-radius: 34px !important;
    color: #ffffff;
    font-size: 17px;
    cursor: pointer;
    gap: 12px;
    width: 200px;
    height: 42px;
    @include sm {
      max-width: 350px;
    }
  }
  .button-submit {
    font-family: $Font_Montserrat_Medium !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    border-radius: 34px !important;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    gap: 12px;
    width: 200px;
    height: 42px;
    @include sm {
      max-width: 350px;
    }
  }
}
.home-info-card-head {
  font-family: $Font_ArchivoBlack;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
  font-weight: bold;
  margin-bottom: 8px;
  position: absolute;
  top: 2px;
  letter-spacing: 0.2em;
  &.noUppercase {
    text-transform: unset;
  }
  &.lineHeightNormal {
    line-height: normal;
  }
  &.font600 {
    font-weight: 600;
  }
}

.home-info-card-head-category {
  font-family: $Font_ArchivoBlack;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 3px;
}
.home-info-card-head-category-arabic {
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 3px;
  font-family: $Font_TajawalBold;
  text-align: right;
  letter-spacing: 0.02em;
}
.home-info-card-text {
  font-family: $Font_Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #5a5f64;
}
.home-info-card-text-arabic {
  font-family: $Font_TajawalRegular;
  font-style: normal;
  font-weight: 400;
  color: #5a5f64;
  font-size: 17px;
  line-height: 130%;
  text-align: right;
  direction: rtl;
}

.home-info-card-brand-text {
  font-family: $Font_ArchivoBlack;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 120% */
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #000000;
}

.home-info-card-maintext {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  margin-top: 8px;
  color: #000000;
}

.home-info-card-maintext-marginless {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  margin-top: 4px;
  color: #000000;
}
.home-info-card-maintext-size3 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  margin-top: 8px;
  color: #000000;
}
.home-info-card-maintext-helper {
  height: auto;
}

.featured-image {
  cursor: pointer;
  max-height: 258px;
  &:hover {
    opacity: 0.7;
  }
}

.home-product-card-text {
  @extend .home-info-card-text;
  margin-top: 20px;
  font-size: 16px;
}

.category-card-text {
  min-height: 12px;
  font-family: $Font_ArchivoBlack;
  font-size: 10px;
  line-height: 130%;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #000000;
  font-weight: bold;
  margin-top: 4px;
}
.category-card-text-arabic {
  font-family: $Font_TajawalBold;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 110%;
  text-align: right;
  letter-spacing: 0em;
  color: #000000;
}
.category-card-subtext {
  font-family: $Font_Montserrat;
  height: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #5a5f64;
  margin-top: 8px;
}
.category-card-subtext-arabic {
  font-family: $Font_TajawalRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  text-align: right;
  color: #5a5f64;
  margin-top: 8px;
}

.swiper-badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  position: absolute;
  height: 14px;
  top: 8px;
  background: #ffffff;
  border-radius: 50px;
  text-transform: uppercase;
  font-family: $Font_Montserrat !important;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  right: 8px;
  text-align: right;
  letter-spacing: 0.9px;
}
.swiper-badge-arabic {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  position: absolute;
  height: 14px;
  top: 8px;
  left: 8px;
  background: #ffffff;
  border-radius: 50px;
  text-align: left;
  font-family: $Font_NotoNaskhArabicRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #000000;
}
.Button-crate-account-home {
  padding: 6px 16px !important;
  font-size: 14px !important;
  background-color: #ffffff !important;
  border-radius: 32px !important;
  color: black !important;
  text-transform: none !important;
  height: 42px !important;
  width: 160px;
  border: 1px solid #000000 !important;
  margin-left: 10px !important;
}
.Button-crate-account-home-arabic {
  padding: 6px 16px !important;
  font-size: 17px !important;
  background-color: #ffffff !important;
  border-radius: 32px !important;
  color: black !important;
  text-transform: none !important;
  height: 42px !important;
  width: 160px;
  border: 1px solid #000000 !important;
  margin-right: 10px !important;
  line-height: 130%;
  text-align: center;
  font-family: $Font_TajawalMedium !important;
}
.howit-card-text {
  font-family: $Font_ArchivoBlack;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #000000;
}
.howit-card-text-arabic {
  font-family: $Font_TajawalBold;
  margin-bottom: 8px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 110%;
  text-align: right;
  letter-spacing: 0.02em;
  color: #000000;
}
.switch-custom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .toggle {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
    background-color: #90969e;
    border-radius: 30px;
    border: 1px solid gray;
    &:hover {
      cursor: pointer;
    }
  }

  /* After slide changes */
  .toggle:after {
    content: "";
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #ffffff;
    top: 1px;
    left: 1px;
    transition: all 0.2s;
  }

  label {
    top: 0px !important;
  }

  /* Checkbox checked effect */
  .checkbox:checked + .toggle::after {
    left: 21px;
  }

  /* Checkbox checked toggle label bg color */
  .checkbox:checked + .toggle {
    background-color: #627997;
  }

  /* Checkbox vanished */
  .checkbox {
    display: none;
  }
}

.button-group-slide {
  width: fit-content !important;
}
.mySwiperHeight {
  min-height: 100%;
}
.mySwiper {
  width: 100%;
}
.swiperMin {
  margin-top: 0px;
  min-height: 50px;
  .swiper-wrapper {
    margin-top: 0 !important;
  }
  .swiper-slide {
    margin-right: 0px !important;
    margin-left: 0px !important;
    border-right: 1px solid white;
  }
  .swiper-slide:last-child {
    border-right: none;
  }
}

.swiperMax {
  margin-top: 0px;
  min-height: 50px;
  .swiper-wrapper {
    margin-top: 0 !important;
  }
}
.swiper-events-offers-landing {
  margin-top: 0px;
  min-height: 50px;
  .swiper-wrapper {
    margin-top: 0 !important;
  }
}
.pagination {
  .swiper-pagination {
    bottom: 50px;
  }
}
.language-swiper {
  .swiper-button-prev {
    left: 40px;
    top: 10px;
    right: auto;
    color: #000000;
    @include xxl {
      top: 10px;
    }
    @include xl {
      top: 10px;
    }
    @include md {
      top: 10px;
    }
    @include sm {
      top: 10px;
    }
  }

  .swiper-button-next {
    left: 0px;
    top: 10px;
    color: #000000;
    right: auto;
    @include xxl {
      top: 10px;
    }
    @include xl {
      top: 10px;
    }
    @include md {
      top: 10px;
    }
    @include sm {
      top: 10px;
    }
  }
}

.dot {
  height: 4px;
  width: 4px;
  background-color: #ced0d7;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  margin-left: 8px;
}
.editorial-swiper {
  margin-top: 0px !important;
  .swiper-wrapper {
    margin-top: 30px !important;
    @include md {
      margin-top: 14px !important;
    }
  }
  .swiper-pagination {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    text-align: left;
    padding-left: 20px;
  }
  &[data-lang="arabic"] {
    .swiper-pagination {
      right: 0;
      left: initial;
      text-align: right !important;
      padding-left: 0px;
      padding-right: 20px;
    }
  }

  .swiper-pagination-bullet {
    background: #5a5f64;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: #ffffff;
    opacity: 1;
  }
}
.custom-swiper {
  .swiper-wrapper {
    margin-top: 0px !important;
  }
}

.nav-select-menu {
  max-width: 320px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 12px 24px -8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 20px;
  @include sm {
    max-width: initial;
    width: initial;
  }
}
.nav-select-menu-light-text {
  font-family: $Font_Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #5a5f64;
}
.nav-select-menu-dark-text {
  font-family: $Font_Montserrat_Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #000000;
}
.nav-select-menu-light-text-arabic {
  font-family: $Font_TajawalRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  color: #5a5f64;
}
.nav-select-menu-dark-text-arabic {
  font-family: $Font_TajawalMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 130%;
  color: #000000;
}

.editorial-info-card-head-category {
  font-family: $Font_ArchivoBlack;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #000000;
  font-weight: bold;
  margin-bottom: 19px;
  margin-top: 4px;
}
.editorial-info-card-head-category-arabic {
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 19px;
  font-family: $Font_TajawalBold;
  text-align: right;
  letter-spacing: 0.02em;
  margin-top: 4px;
}
.select-icon {
  position: absolute;
  right: 12.08px;
  z-index: -1;
  left: auto;
}
.select-icon-arabic {
  position: absolute;
  right: auto;
  z-index: -1;
  left: 12.08px;
}
.policy {
  ul li::marker {
    font-size: 12px;
  }
  ul {
    padding-inline-start: 20px !important;
  }
}
.reward-box {
  width: 360px;
  height: 240px;
  max-width: 360px;
  border-radius: 14px;
  height: 240px;
  @include sm {
    width: 156px;
    height: 104px;
  }
}
.swiperMinTop {
  min-height: 100%;

  .swiper-slide {
    top: 0px !important;
    border-right: 0px solid white;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }
}

.list {
  list-style-type: decimal;
  padding-left: 32px;
  font-family: $Font_Montserrat;
}
.amber-swiper {
  .swiper-slide {
    top: 40px;
  }
}

main {
  .referalValidAlert {
    .MuiAlert-icon {
      opacity: 0;
      position: absolute;
      margin: 0;
      z-index: -1;
    }
    .MuiAlert-message {
      padding: 0;
      overflow: hidden;
    }
  }
}
