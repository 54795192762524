html,
body,
#root {
  height: max-content;
}

body {
  margin: 0;
  overflow: auto;
  overflow-x: hidden;
}

.MuiTabs-scroller .MuiTabs-hideScrollbar .MuiTabs-scrollableX {
  display: flex;
  justify-content: center;
}

.MuiButton-root {
  box-shadow: none !important;
}

.MuiButton-root:hover {
  box-shadow: none !important;
}

.MuiAutocomplete-popupIndicator {
  color: #888;
}

.MuiAutocomplete-popupIndicator:hover {
  color: #555;
}

.MuiAutocomplete-popupIndicator.Mui-disabled {
  color: #ccc;
}

.MuiAutocomplete-listbox::-webkit-scrollbar {
  width: 8px;
  background-color: #f1f1f1;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.truncated-text-content {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncated-text-content.truncate {
  -webkit-line-clamp: unset;
}
