@use "./SignUp";
@use "./Login";
@use "./Home";
@use "./HowItWorks";
@use "./AmberApp";
@use "./RewardSystem";
@use "./Footer";
@use "./BrandsStore";
@use "./EventsAndOffers";
@use "./Dashboard";
@use "./AccountSettings";
@use "./SupportPages";
@use "./AboutUs";
@use "./Faq";
@use "./FinancialServices";
@use "./Alliance";
@use "./Error";
@use "./Editorial";
@use "./PrivacyAndPolicy";
@use "./TermsAndConditions";
@use "./PurchaseHistoryAndDetails";

body {
  margin: 0;
}
button,
a,
div,
select,
textarea {
  -webkit-tap-highlight-color: transparent; /* iOS için */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Android için */
}

.KeyboardArrowDownIconEnglish {
  position: absolute;
  right: 20px;
  left: auto;
}

.KeyboardArrowDownIconArabic {
  position: absolute;
  left: 20px;
  right: auto;
}
.list-none {
  text-decoration: none;
}
